import { Avatar, Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import * as React from 'react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { GetMDBportfolioListData } from 'ApiRequestService';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

// Define the data type to match the API response
type Portfolio = {
	portfolio_owner_id: string; // Add this line if it's not already present
	portfolio_name: string;
	investor_count: number;
	total_investment_amount: string;
};

const columnHelper = createColumnHelper<Portfolio>();

// Function to format currency
const formatCurrency = (amount: number) => {
	return new Intl.NumberFormat('en-IN', {
		style: 'currency',
		currency: 'INR',
		maximumFractionDigits: 0,
	}).format(amount);
};

export default function TopCreatorTable() {
	const [sorting, setSorting] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const [portfolioList, setPortfolioList] = useState<Portfolio[]>([]);
	const navigate = useNavigate(); // Initialize navigate

	// Fetch the portfolio data from API
	useEffect(() => {
		fetchAllPortfolios();
	}, []);

	const fetchAllPortfolios = async () => {
		try {
			const response = await GetMDBportfolioListData();
			if (response && response.data) {
				const InvestorList = response.data.data;
				setPortfolioList(InvestorList);
			} else {
				console.log('No data found in response:', response);
			}
		} catch (error) {
			console.log('Error fetching data:', error);
		}
	};

	// Handle portfolio click navigation using portfolio_owner_id
	const handlePortfolioClick = (portfolioOwnerId: string) => {
		sessionStorage.setItem('portfolio_ID', portfolioOwnerId);
		navigate('/admin/portfolio-dashboard');
	};

	// Define columns based on the API response
	const columns = [
		columnHelper.accessor('portfolio_name', {
			id: 'portfolio_name',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Portfolio Name
				</Text>
			),
			cell: (info) => (
				<Flex align="center" cursor="pointer" onClick={() => handlePortfolioClick(info.row.original.portfolio_owner_id)} _hover={{ textDecoration: 'underline', color: 'blue.500' }}>
					<Avatar
						src={`https://robohash.org/${info.getValue()}?set=set5`} // Generates random avatars based on portfolio name
						size="sm"
						mr="4" // Add some space between the image and the text
					/>
					<Text
						color={textColor}
						fontSize='sm'
						fontWeight='600'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('total_investment_amount', {
			id: 'total_investment_amount',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Total Investment (INR)
				</Text>
			),
			cell: (info) => (
				<Text
					color={textColorSecondary}
					fontSize='sm'
					fontWeight='500'>
					{formatCurrency(parseInt(info.getValue()))}
				</Text>
			)
		}),
		columnHelper.accessor('investor_count', {
			id: 'investor_count',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Total Investors
				</Text>
			),
			cell: (info) => (
				<Text
					color={textColorSecondary}
					fontSize='sm'
					fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
	];

	const table = useReactTable({
		data: portfolioList,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return (
		<Card justifyContent="center" alignItems="center" flexDirection="column" w="100%" mb="0px" maxHeight="55vh">
			<Flex
				direction='column'
				w='100%'
				overflowX="hidden"> {/* Hide horizontal scroll */}
				<Flex
					align={{ sm: "flex-start", lg: "center" }}
					justify='space-between'
					w='100%'
					px='22px'
					pb='3px'
					mb='3px'
					boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
					<Text color={textColor} fontSize='xl' fontWeight='600'>
						Portfolio Owners
					</Text>
				</Flex>
				<Box overflowY="auto" overflowX="hidden"  sx={{
					'::-webkit-scrollbar': {
						width: '6px',
					},
					'::-webkit-scrollbar-thumb': {
						background: 'gray.400',
						borderRadius: '10px',
					},
					'::-webkit-scrollbar-track': {
						background: 'gray.200',
					},
				}}>
					<Table variant='simple' color='gray.500' mt="12px">
						<Thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px'
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}
												{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									))}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{table.getRowModel().rows.map((row) => (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => (
										<Td
											key={cell.id}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', lg: '200px' }}
											borderColor={borderColor}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Td>
									))}
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
			</Flex>
		</Card>
	);
}
