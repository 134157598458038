import React, { useEffect, useState } from 'react';
import { SimpleGrid, Box, Alert, AlertDescription, AlertIcon, AlertTitle, Button } from '@chakra-ui/react';
import { ProfileCard } from '../../../components/dashboardComponents/ProfileCard';
import { InvestmentList } from '../investment/InvestmentTables';
import { GetInvestorBank } from 'ApiRequestService';
import { useNavigate } from 'react-router-dom';

const InvestorDashboard: React.FC = () => {

    const [investorBankList, setInvestorBankList] = useState<any[]>([]);
    const [isDataFetch, setIsDataFetch] = useState(false);

    const currentPath = window.location.pathname;
    const navigate = useNavigate();

    useEffect(() => {
        sessionStorage.setItem('pageName', 'Investor Dashboard');
        const investor_ID = sessionStorage.getItem('investor_ID');
        fetchBankDropDowns(investor_ID);
    }, [currentPath]);

    const fetchBankDropDowns = async (investorID: string) => {
        try {
            const response = await GetInvestorBank({ id: investorID });
            if (response && response.data) {
                setIsDataFetch(true);
                const decryptedBankList = response.data.data;  // Adjust as per actual response structure
                if (decryptedBankList.length > 0) {
                    setInvestorBankList(decryptedBankList);
                } else {
                    console.log('No data found in response:');
                }
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const handleBankAddAction = () => {
        sessionStorage.setItem('isAddBank','true')
        navigate('/admin/investor-view');
    };

    return (
        <Box pt={{ base: '50px' }}>
            <Box
                border="1px"
                borderColor="gray.400"
                borderRadius="md"
                p={6}
                boxShadow="lg"
            >
                <SimpleGrid columns={{ base: 1 }}>
                    <ProfileCard />
                </SimpleGrid>

                {/* Conditional Rendering */}
                {isDataFetch && (
                    investorBankList.length === 0 ? (
                        <Alert status="warning" borderRadius="md" my={4}>
                            <AlertIcon />
                            <Box>
                                <AlertTitle>No Banks Found for this Investor!</AlertTitle>
                                <AlertDescription>
                                    You currently have no Banks added. Please add a bank account to start investing.
                                </AlertDescription>
                            </Box>
                            <Button colorScheme="blue" ml="auto" onClick={handleBankAddAction}>
                                Add Bank Account
                            </Button>
                        </Alert>
                    ) : (
                        <InvestmentList />
                    )
                )}
            </Box>
        </Box>
    );
};

export { InvestorDashboard };
