import React, { useEffect, useState, FC } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Badge, Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { ListInvestment, PortfolioInvestmentListAll } from 'ApiRequestService';
import { useNavigate } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { ColDef } from 'ag-grid-community';
import { dateFormatddmmyy, reverseDateFormat } from 'commonfunction/CommonFunction';
import { BsDatabaseFillAdd, BsEyeFill, BsFileEarmarkTextFill, BsPencilSquare } from 'react-icons/bs';

// Define the Investment interface
interface Investment {
    investor_id: number;
    investor_investment_id: any;
    amount: number;
    end_date: string;
    invested_date: string;
    portfolio_name: string;
    portfolio_percent: number;
    portfolio_percent_share: number;
    status: string;
    sub_marketer_name: string;
    sub_marketer_percent: number;
    sub_marketer_percent_share: number;
}

// Custom cell renderer component
const ActionRenderer: FC<{ value: any; data: Investment; context: any }> = ({ data,context  }) => {
    const { navigate } = context;

    const handleEditAction = (id: any) => {
        sessionStorage.setItem('investor_investment_id', id);
        navigate('/admin/investment-view');
        sessionStorage.setItem('investmentNavigate', 'portfolio-dashboard');
    };


    return (
        <>
            <Button
                style={{ width: '50px', margin: '2px' }}
                colorScheme="blue"
                title="view"
                onClick={() => handleEditAction(data.investor_investment_id)}
            >
                <BsEyeFill />
            </Button>
        </>
    );
};

const StatusRenderer: FC<{ value: string }> = ({ value }) => {
    const colorScheme = value === 'Active' ? 'green' : value === 'Closed' ? 'red' : 'gray'; // Add more conditions if needed
    return (
        <Badge colorScheme={colorScheme} fontSize="0.9em" p={1}>
            {value}
        </Badge>
    );
};

const PortfolioInvestmentList: React.FC = () => {
    const [investmentList, setInvestmentList] = useState<Investment[]>([]);
    const [gridApi, setGridApi] = useState<any>(null);
    const [showAggrement, setShowAggrement] = useState(false); // State to show/hide agreement modal
    const [selectedInvestmentId, setSelectedInvestmentId] = useState<any>(null); // Store selected investment ID

    const navigate = useNavigate();

    useEffect(() => {
        sessionStorage.setItem('pageName', 'Portfolio Dashboard')
        fetchAllInvestment();
    }, []);

    // Fetch investments
    const fetchAllInvestment = async () => {
        try {
            const portfolio_ID = sessionStorage.getItem('portfolio_ID');
            const response = await PortfolioInvestmentListAll({ id: portfolio_ID });

            if (response && response.data) {
                setInvestmentList(response.data.data);
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    // Format currency
    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0,
        }).format(amount);
    };

    // Define columns for AG Grid
    const columnDefs: ColDef[] = [
        { headerName: 'ID', field: 'investor_investment_id', sortable: true, filter: true,width:100 },        
        { headerName: 'Investor Name', field: 'name', sortable: true, filter: true, },
        {
            headerName: 'Status',
            field: 'status',
            sortable: true,
            filter: true,
            cellRenderer: StatusRenderer, // Custom status badge renderer
        },
        { headerName: 'Amount', field: 'amount', sortable: true, filter: true, valueFormatter: (params) => formatCurrency(params.value) },
        { headerName: 'Investor %', field: 'investor_percentage', sortable: true, filter: true,width:150 },
        { headerName: 'Investor ₹', field: 'investor_percent_share', sortable: true, filter: true, valueFormatter: (params) => formatCurrency(params.value) },
        { headerName: 'Invested Date', field: 'invested_date', sortable: true, filter: true, valueFormatter: (params) => dateFormatddmmyy(params.value) },
        { headerName: 'End Date', field: 'end_date', sortable: true, filter: true, valueFormatter: (params) => dateFormatddmmyy(params.value) },
        // { headerName: 'Portfolio', field: 'portfolio_name', sortable: true, filter: true },
        { headerName: 'Portfolio %', field: 'portfolio_percent', sortable: true, filter: true,width:150 },
        { headerName: 'Portfolio ₹', field: 'portfolio_percent_share', sortable: true, filter: true, valueFormatter: (params) => formatCurrency(params.value) },
        { headerName: 'Sub-Marketer', field: 'sub_marketer_name', sortable: true, filter: true },
        { headerName: 'Sub-Marketer %', field: 'sub_marketer_percent', sortable: true, filter: true,width:150 },
        { headerName: 'Sub-Marketer ₹', field: 'sub_marketer_percent_share', sortable: true, filter: true, valueFormatter: (params) => formatCurrency(params.value) },
        {
            headerName: 'Actions', width: 150,
            field: 'actions', pinned: "right", lockPinned: true, sortable: false, filter: false ,
            cellClass: "lock-pinned",
            cellRenderer: 'actionRenderer', // Use the custom cell renderer
            cellRendererParams: {
                context: {
                    navigate,
                }
            }
        }
    ];

    // Grid options and event handlers
    const onGridReady = (params: any) => {
        setGridApi(params.api);
    };

    const handleAddAction = () => {
        navigate('/admin/investment-add');
    };

    const agGridTheme = useColorModeValue('ag-theme-alpine', 'ag-theme-alpine-dark');

    return (
        <Box className={agGridTheme} height="60vh" marginTop={'2%'}>
            <Flex mb="4" align="center" gap="4">
                <Box flex="0 0 25%">
                    <Text fontSize="xl" fontWeight="bold" m={3} color='teal'>Portfolio Investments List</Text>
                </Box>
            </Flex>
            <Box height={'50vh'}  overflow="auto">
                <AgGridReact
                rowHeight={50}
                    rowData={investmentList}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    pagination={true}
                    paginationPageSize={10}
                    domLayout="normal"
                    animateRows={true}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}
                    components={{
                        actionRenderer: ActionRenderer // Register the custom cell renderer
                    }}
                />
            </Box>
        </Box>
    );
};

export { PortfolioInvestmentList };
