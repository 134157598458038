import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.jpg";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { LoginUser } from "ApiRequestService"; // Add VerifyTFA API call
import useCustomAlert from "components/alert/alerts";
import { getIsAdmin } from "commonfunction/CommonFunction";

function SignIn() {
  const { showAlert } = useCustomAlert();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isTFAEnabled, setIsTFAEnabled] = useState(false); // State to check TFA
  const [TFAQRCode, setTFAQRCode] = useState(""); // Store QR code if needed
  const [TFAcode, setTFAcode] = useState(""); // Store 6-digit TFA code

  const handleClick = () => setShow(!show);

  const onSignInClick = async () => {
    try {
      if (email && password) {
        const data = { email, password };
        const response = await LoginUser(data);

        if (response.status === 200) {
          const { token, data: userData, isTFAEnabled, TFAQRCode, tfaRequired } = response.data;

          // Check if TFA is enabled
          if (isTFAEnabled) {
            setIsTFAEnabled(true);
            setTFAQRCode(TFAQRCode); // Show QR code if TFA is not set up
          } else if (tfaRequired) {
            setIsTFAEnabled(true);
          } else {
            completeLogin(userData, token); // Complete login if TFA is not enabled
          }
        } else {
          showAlert("error", "Error", "Please Enter Correct Credentials");
        }
      } else {
        showAlert("info", "Info", "Please Enter Credentials");
      }
    } catch (error) {
      showAlert("error", "Error", "Error during sign-in process");
    }
  };

  const onVerifyTFAClick = async () => {
    try {
      if (TFAcode) {
        const response = await LoginUser({ tfaCode: TFAcode, email, password }); // Call TFA verify API

        if (response.status === 200) {
          const { token, data } = response.data;
          completeLogin(data, token); // Complete login after successful TFA verification
        } else {
          showAlert("error", "Error", "Invalid TFA code");
        }
      } else {
        showAlert("info", "Info", "Please enter the TFA code");
      }
    } catch (error) {
      showAlert("error", "Error", "Invalid TFA code");
    }
  };

  const completeLogin = (userData: any, token: string) => {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("appUser", JSON.stringify(userData));
    showAlert("success", "Success", "Signed In successfully!");

    if (getIsAdmin()) {
      navigate("/admin/default");
    } else {
      navigate("/admin/investor-list");
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "70px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="30px" mb="20px">
            Investor Management System
          </Heading>
        </Box>
        <Box me="auto">
          <Heading color={textColor} fontSize="20px" mb="10px" textAlign={'center'}>
            Sign In
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          {/* Email and Password input fields */}
          {!isTFAEnabled && (
            <>
              <Box me="auto">
                <Text mb="16px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                  Enter your email and password to sign in!
                </Text>
              </Box>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Email
                </FormLabel>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="auth"
                  fontSize="sm"
                  type="email"
                  placeholder="Enter Your Email here..."
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor}>
                  Password
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fontSize="sm"
                    placeholder="Enter Your Password here..."
                    mb="24px"
                    size="lg"
                    type={show ? "text" : "password"}
                    variant="auth"
                  />
                  <InputRightElement display="flex" alignItems="center" mt="4px">
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                <Button onClick={onSignInClick} fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px">
                  Sign In
                </Button>
              </FormControl>
            </>
          )}

          {/* TFA Code input field */}
          {isTFAEnabled && (
            <>
              {TFAQRCode && (
                <Box>
                  <Text>Scan this QR code using your authenticator app:</Text>
                  <img src={TFAQRCode} alt="QR Code" style={{ marginBottom: "20px" }} />
                </Box>
              )}
              <FormControl>
                <FormLabel ms="4px" fontSize="md" fontWeight="500" color={textColor} mb="8px" mt='10px'>
                  Enter TFA Code
                </FormLabel>
                <Input
                  value={TFAcode}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only numbers and limit to 6 digits
                    if (/^\d{0,6}$/.test(value)) {
                      setTFAcode(value);
                    }
                  }}
                  variant="auth"
                  fontSize="sm"
                  type="number" // Set type to number
                  placeholder="Enter the 6-digit code"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
                <Button onClick={onVerifyTFAClick} fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px">
                  Verify TFA
                </Button>
              </FormControl>
            </>
          )}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
