import { Box, Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { GetMDByearChartData } from 'ApiRequestService';
import Card from 'components/card/Card';
import LineChart from 'components/charts/LineChart';
import { useEffect, useState } from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdBarChart, MdOutlineCalendarToday } from 'react-icons/md';
import { RiArrowUpSFill } from 'react-icons/ri';

export default function TotalSpent(props: { [x: string]: any }) {
	const { ...rest } = props;
	const [data1, setData1] = useState<number[]>([]);
	const [data2, setData2] = useState<number[]>([]);
	const [dataMonth, setDataMonth] = useState<string[]>([]);

	useEffect(() => {
		fetchLastYearData();
	}, []);

	const fetchLastYearData = async () => {
		try {
			const response = await GetMDByearChartData();
			if (response && response.data) {
				const dashboardData = response.data.data; // Ensure this structure matches your API
				if (dashboardData.length > 0) {
					// Create arrays using map instead of manual loops
					const tempData1 = dashboardData.map((item: any) => Number(item.investment_amount));
					const tempData2 = dashboardData.map((item: any) => Number(item.investment_amount_minus_payout));
					const tempDataMonth = dashboardData.map((item: any) => item.month);

					// Update the state with the data
					setData1(tempData1);
					setData2(tempData2);
					setDataMonth(tempDataMonth);
				}
			} else {
				console.log('No data found in response:', response);
			}
		} catch (error) {
			console.log('Error fetching data:', error);
		}
	};

	const lineChartDataTotalSpent = [
		{
			name: 'Total Investments',
			data: data1,
		},
		{
			name: 'Amount After Payout',
			data: data2,
		},
	];

	const lineChartOptionsTotalSpent: any = {
		chart: {
			toolbar: { show: false },
			dropShadow: {
				enabled: true,
				top: 13,
				left: 0,
				blur: 10,
				opacity: 0.1,
				color: '#4318FF',
			},
		},
		colors: ['#4318FF', '#39B8FF'],
		markers: {
			size: 0,
			colors: 'white',
			strokeColors: '#7551FF',
			strokeWidth: 3,
			strokeOpacity: 0.9,
			fillOpacity: 1,
		},
		tooltip: { theme: 'dark' },
		dataLabels: { enabled: false },
		stroke: { curve: 'smooth' },
		xaxis: {
			categories: dataMonth,
			labels: { style: { colors: '#A3AED0', fontSize: '12px', fontWeight: '500' } },
			axisBorder: { show: false },
			axisTicks: { show: false },
		},
		yaxis: { show: false },
		legend: {
			show: true,
			position: 'top', // You can position it "top", "right", "bottom", or "left"
			horizontalAlign: 'center', // Align legend items
			fontSize: '14px',
			fontFamily: undefined,
			fontWeight: 500,
			labels: {
				colors: '#A3AED0' // Set the color for the legend text
			},
			markers: {
				width: 10,
				height: 10,
				strokeWidth: 0,
				strokeColor: '#fff',
				radius: 10
			},
			itemMargin: {
				horizontal: 10,
				vertical: 5
			}
		},
		grid: { show: false },
	};

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const iconColor = useColorModeValue('brand.500', 'white');
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

	return (
		<Card justifyContent="center" alignItems="center" flexDirection="column" w="100%" mb="0px" {...rest}>
			<Flex align="center" justify="space-between" w="100%" pe="20px" pt="5px">
				{/* <Button bg={boxBg} fontSize="sm" fontWeight="500" color={textColorSecondary} borderRadius="7px">
                 <Icon as={MdOutlineCalendarToday} color={textColorSecondary} me="4px" />
                 This month
               </Button> */}
				<Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
					Yearly Analysis
				</Text>
			
				<Button
					ms="auto"
					alignItems="center"
					justifyContent="center"
					bg={bgButton}
					_hover={bgHover}
					_focus={bgFocus}
					_active={bgFocus}
					w="37px"
					h="37px"
					lineHeight="100%"
					borderRadius="10px"
					{...rest}
				>
					<Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
				</Button>

			</Flex>
			{/* <Flex w="100%" flexDirection={{ base: 'column', lg: 'row' }}>
			<Flex align="center" mb="20px">
					<Text color="secondaryGray.600" fontSize="sm" fontWeight="500" mt="4px" me="6px">
						Total Raised
					</Text>
					<Flex align="center">
						<Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
						<Text color="green.500" fontSize="sm" fontWeight="700">
							+2.45%
						</Text>
					</Flex>
				</Flex>
			</Flex> */}
			<Flex w="100%" flexDirection={{ base: 'column', lg: 'row' }}>
				{/* <Flex flexDirection="column" me="10px" mt="28px">
          <Text color={textColor} fontSize="34px" textAlign="start" fontWeight="700" lineHeight="100%">
            $37.5K
          </Text>
          <Flex align="center" mb="20px">
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500" mt="4px" me="12px">
              Total Spent
            </Text>
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
              <Text color="green.500" fontSize="sm" fontWeight="700">
                +2.45%
              </Text>
            </Flex>
          </Flex>

          <Flex align="center">
            <Icon as={IoCheckmarkCircle} color="green.500" me="4px" />
            <Text color="green.500" fontSize="md" fontWeight="700">
              On track
            </Text>
          </Flex>
        </Flex> */}
				<Box minH="260px" minW="95%" mt="auto" ms='10px'>
					{data1.length > 0 && dataMonth.length > 0 ? (
						<LineChart chartData={lineChartDataTotalSpent} chartOptions={lineChartOptionsTotalSpent} />
					) : (
						<Text>No Data Available</Text>
					)}
				</Box>
			</Flex>
		</Card>
	);
}
