export function dateFormatChange(dateValue: any) {
    const newdate = new Date(dateValue);

    // Format the date to YYYY-MM-DD
    const year = newdate.getFullYear();
    const month = String(newdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(newdate.getDate()).padStart(2, '0');

    // Return formatted date in the format YYYY-MM-DD
    return `${year}-${month}-${day}`;
}


export function reverseDateFormat(value :any) {

    const date = new Date(value)
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();

    const formatTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`

    return formatTime
}

export function dateFormatddmmyy(value :any) {

    const date = new Date(value)
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();

    const formatTime = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`

    return formatTime
}

export function fetchUserName(){
    
	var AppUser = JSON.parse(sessionStorage.getItem('appUser')) || []
    var AppUserName = AppUser.firstname +" "+AppUser.lastname
    return AppUserName
}


export function  getShortMonth() {
    const date = new Date();
    // Get the short form of the month (e.g., "Sep" for September)
    const shortMonth = date.toLocaleString('en-US', { month: 'short' });
    // Convert to uppercase
    return shortMonth.toUpperCase();
  };

  
export function getIsAdmin(){    
	var AppUser = JSON.parse(sessionStorage.getItem('appUser')) || []
    var AppUserRole = AppUser.role
    if(AppUserRole === 'admin'){
        return true
    }else{
        return false
    }
}
