import React, { useEffect, useState } from 'react';
import { SimpleGrid, Box, Alert, AlertDescription, AlertIcon, AlertTitle, Button } from '@chakra-ui/react';
import { PortfolioProfileCard } from '../../../components/dashboardComponents/PortfolioProfileCard';
import { InvestmentList } from '../investment/InvestmentTables';
import { GetInvestorBank } from 'ApiRequestService';
import { useNavigate } from 'react-router-dom';
import { PortfolioInvestmentList } from './PortfolioInvestments';

const PortfolioDashboard: React.FC = () => {


    sessionStorage.setItem('pageName','Portfolio Dashboard')
    return (
        <Box pt={{ base: '50px' }}>
            <Box
                border="1px"
                borderColor="gray.400"
                borderRadius="md"
                p={6}
                boxShadow="lg"
            >
                <SimpleGrid columns={{ base: 1 }}>
                    <PortfolioProfileCard />
                </SimpleGrid>
                <PortfolioInvestmentList />
            </Box>
        </Box>
    );
};

export { PortfolioDashboard };
