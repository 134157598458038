import React, { FC, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Input,
    useColorModeValue,
    Flex,
    Avatar,
} from '@chakra-ui/react';
import { CreateInvestor, CreateInvestorBankDetails, ListInvestor } from 'ApiRequestService';
import { useNavigate } from 'react-router-dom';
import {
    BsPencilSquare,
    BsBarChartLineFill,
    BsPersonFillAdd,
} from 'react-icons/bs';
import { DecryptDataList, EncryptDataList } from 'utils/encryption';

import data from './data.json'
import { dateFormatChange } from 'commonfunction/CommonFunction';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
interface Investor {
    investor_id: string;
    firstname: string;
    lastname: string;
    aadar_number: any;
    phone: any;
    total_invested_amount: string;
    total_investment: any;
    pan_number: any;
}

// Custom cell renderer component
const ActionRenderer: FC<{ value: any; data: Investor; context: any }> = ({ data, context }) => {
    const { navigate, setShowAggrement, setSelectedInvestmentId } = context;

    const handleEditAction = (id: string) => {
        sessionStorage.setItem('isAddBank', 'false')
        sessionStorage.setItem('investor_ID', id);
        navigate('/admin/investor-view');
    };

    const handleDashboardAction = (id: string) => {
        sessionStorage.setItem('investor_ID', id);
        navigate('/admin/investor-dashboard');
    };

    return (
        <>
            <Button
                style={{ width: '50px', margin: '1px' }}
                colorScheme="blue"
                title="Edit"
                onClick={() => handleEditAction(data.investor_id)}
            >
                <BsPencilSquare />
            </Button>
            <Button
                style={{ width: '50px', margin: '1px' }}
                colorScheme="pink"
                title="Dashboard"
                onClick={() => handleDashboardAction(data.investor_id)}
            >
                <BsBarChartLineFill />
            </Button>
        </>
    );
};

const InvestorList: React.FC = () => {
    const [investorList, setInvestorList] = useState<Investor[]>([]);
    const [filterInput, setFilterInput] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchAllInvestor();
    }, []);

    // Fetch investors
    const fetchAllInvestor = async () => {
        try {
            const response = await ListInvestor();
            console.log(response);

            if (response && response.data) {
                const InvestorList = DecryptDataList(response.data.data) as Investor[]
                setInvestorList(InvestorList);
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    // Handle actions
    const handleEditAction = (id: string) => {
        sessionStorage.setItem('isAddBank', 'false')
        sessionStorage.setItem('investor_ID', id);
        navigate('/admin/investor-view');
    };

    const handleDashboardAction = (id: string) => {
        sessionStorage.setItem('investor_ID', id);
        navigate('/admin/investor-dashboard');
    };

    const handleAddAction = async () => {
        navigate('/admin/investor-add');
        sessionStorage.setItem('investor_ID', '');


        // let response = EncryptDataList(data)
        // if (response.length === 728) {
        //     for (let i = 0; i<response.length; i++) {
        //        await CreateInvestorBankDetails(response[i]);
        //     }
        // }
    };

    // Format currency
    const formatCurrency = (amount: string) => {
        if (amount) {
            const numberAmount = parseFloat(amount.replace(/,/g, '')); // Remove commas if any and convert to number
            return new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR',
                maximumFractionDigits: 0,
            }).format(numberAmount);
        }
    };

    // Define columns for react-table
    const columnDefs:ColDef[] = [
        {
            headerName: 'ID', sortable: true, filter: true,
            field: 'investor_id', width:100,
        },
        {
            headerName: 'Name', sortable: true, filter: true,
            field: 'name',
            valueGetter: (params: { data: { firstname: any; lastname: any; }; }) => `${params.data.firstname} ${params.data.lastname}`,
            cellRenderer: (params: { data: { firstname: any; lastname: any; investor_id: any; }; }) => (
                <Flex align="center">
                    <Avatar
                        name={`${params.data.firstname} ${params.data.lastname}`}
                        // src={`https://thispersondoesnotexist.com/?id=${params.data.investor_id}`}
                        size="sm"
                        mr="4"
                    />
                    <span>{`${params.data.firstname} ${params.data.lastname}`}</span>
                </Flex>
            ),
        },
        {
            headerName: 'Aadhar Number', sortable: true, filter: true,
            field: 'aadar_number',
            valueGetter: (params: { data: { aadar_number: any; }; }) => {
                const aadhar = params.data.aadar_number;
                return `XXXX-XX${aadhar?.slice(-7)}`;  // Mask first 6 digits and show last 6
            },
        },
        {
            headerName: 'Pan Number', sortable: true, filter: true,
            field: 'pan_number',
        },
        {
            headerName: 'Phone', sortable: true, filter: true,
            field: 'phone',
        },
        {
            headerName: 'Total Invested Amount', sortable: true, filter: true,
            field: 'total_invested_amount',
            valueFormatter: (params: { value: string; }) => formatCurrency(params.value), // You can format this based on your formatCurrency function
        },
        {
            headerName: 'Total Investment', sortable: true, filter: true,
            field: 'total_investment',
        },
        {
            headerName: 'Actions', width: 150,
            field: 'actions', pinned: "right", lockPinned: true,sortable: false, filter: false ,
            cellClass: "lock-pinned",
            cellRenderer: 'actionRenderer', // Use the custom cell renderer
            cellRendererParams: {
                context: {
                    navigate,
                }
            }
        },
    ];



    const agGridTheme = useColorModeValue('ag-theme-alpine', 'ag-theme-alpine-dark');

    // Styles based on theme
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    return (
        <Box
            pt={{ base: '70px' }}
            position="relative"
            height="93vh"
            overflow="hidden" className={agGridTheme}
        >
            <Flex mb="4" align="center" gap="4">
                {/* <Box flex="1">
                    <Input
                        value={filterInput}
                        onChange={handleFilterChange}
                        placeholder="Search..."
                        width="100%"
                    />
                </Box> */}
                <Box flex="0 0 25%">
                    <Button colorScheme="green" onClick={handleAddAction} width="50%">
                        <BsPersonFillAdd /> <span style={{ marginLeft: '2px' }}>Add Investor</span>
                    </Button>
                </Box>
            </Flex>

            <Box
                position="relative"
                height="calc(92vh - 70px - 48px)" // Adjust height based on header and actions button
                overflow="hidden"
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    overflowX="auto"
                    overflowY="auto"
                    padding="0"
                    border="1px"
                    borderColor={borderColor}
                >
                    <AgGridReact
                        rowHeight={50}
                        rowData={investorList}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={50}
                        domLayout="normal"
                        animateRows={true}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            resizable: true,
                        }}
                        components={{
                            actionRenderer: ActionRenderer // Register the custom cell renderer
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export { InvestorList };
