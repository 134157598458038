import axios from "axios";
import api from './interceptors/api-interceptors';


//export const LOCALHOST_URL = 'http://localhost:7000/api/'


export const LOCALHOST_URL = 'https://ims.profitevertraders.com/api/'

export const LOGIN = `${LOCALHOST_URL}/login`

export const INVESTOR = `${LOCALHOST_URL}/investor`
export const INVESTMENT = `${LOCALHOST_URL}/investment`
export const DROPDOWN = `${LOCALHOST_URL}/dropdown`
export const MAINDB = `${LOCALHOST_URL}/main-db`
export const IBANKDETAILS = `${LOCALHOST_URL}/investor-bank`
export const REPORTS = `${LOCALHOST_URL}/reports`
export const PORTFOLIO = `${LOCALHOST_URL}/portfolio`

//Create Client
export function LoginUser(data: any) {
    return axios.post(`${LOGIN}/validateUser`, data);
}


//Investor API

export function CreateInvestor(data: any) {
    return api.post(`${INVESTOR}/create`, data);
}

export function UpdateInvestor(data: any) {
    return api.post(`${INVESTOR}/update`, data);
}

export function GetInvestor(data: any) {
    return api.post(`${INVESTOR}/get`, data);
}

export function DeleteInvestor(data: any) {
    return api.post(`${INVESTOR}/delete`, data);
}

export function ListInvestor() {
    return api.post(`${INVESTOR}/list`);
}



//Investment API

export function CreateInvestment(data: any) {
    return api.post(`${INVESTMENT}/create`, data);
}

export function UpdateInvestment(data: any) {
    return api.post(`${INVESTMENT}/update`, data);
}

export function GetInvestment(data: any) {
    return api.post(`${INVESTMENT}/get`, data);
}

export function DeleteInvestment(data: any) {
    return api.post(`${INVESTMENT}/delete`, data);
}

export function ListInvestment(data:any) {
    return api.post(`${INVESTMENT}/list`,data);
}


export function DashboardDataInvestment(data:any) {
    return api.post(`${INVESTMENT}/dashboard-data`,data);
}

export function InvestmentAggrement(data:any) {
    return api.post(`${INVESTMENT}/investment-aggrement`,data);
}
//dropdown


export function GetInvestorBank(data: any) {
    return api.post(`${DROPDOWN}/investor-bank-list`, data);
}

export function GetPortfolioList() {
    return api.post(`${DROPDOWN}/portfolio-list`);
}

export function GetSubMarketerList(data: any) {
    return api.post(`${DROPDOWN}/sub-marketer-list`, data);
}

export function GetReceiverBank() {
    return api.post(`${DROPDOWN}/receiver-bank-list`);
}


//main Dashboard


export function GetMDBCardData() {
    return api.post(`${MAINDB}/card-data`);
}


export function GetMDBWeekChartData() {
    return api.post(`${MAINDB}/week-chart-data`);
}


export function GetMDByearChartData() {
    return api.post(`${MAINDB}/year-chart-data`);
}
export function GetMDBportfolioListData() {
    return api.post(`${MAINDB}/portfolio-list-data`);
}
export function GetMDBMonthChartData() {
    return api.post(`${MAINDB}/month-chart-data`);
}

//Investor API

export function CreateInvestorBankDetails(data: any) {
    return api.post(`${IBANKDETAILS}/create`, data);
}

export function UpdateInvestorBankDetails(data: any) {
    return api.post(`${IBANKDETAILS}/update`, data);
}

export function GetInvestorBankDetails(data: any) {
    return api.post(`${IBANKDETAILS}/get`, data);
}

export function DeleteInvestorBankDetails(data: any) {
    return api.post(`${IBANKDETAILS}/delete`, data);
}

export function ListInvestorBankDetails(data: any) {
    return api.post(`${IBANKDETAILS}/list`,data);
}

//Report
export function ReportInvestmentsDetails(data: any) {
    return api.post(`${REPORTS}/investmens-details`,data);
}
export function ReportPayOutDetails(data: any) {
    return api.post(`${REPORTS}/payout-details`,data);
}

//Portfolio

export function PortfolioListAll() {
    return api.post(`${PORTFOLIO}/list`);
}

export function PortfolioInvestmentListAll(data:any) {
    return api.post(`${PORTFOLIO}/pInstment-list`,data);
}

export function PortfolioDashboardData(data:any) {
    return api.post(`${PORTFOLIO}/pDashboard-data`,data);
}