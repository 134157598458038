import React, { useState } from "react";
import axiosInstance from "./api-interceptors";
import { HashLoader } from "react-spinners";

const Loader: React.FC = () => {
    const [loading, setLoading] = useState(false);
    let noOfAPI = 0


    axiosInstance.interceptors.request.use(
        (config: any) => {
            if (noOfAPI === 0) {
                setLoading(true);
            }
            noOfAPI = noOfAPI + 1
            return config;
        }, (error: any) => {
            noOfAPI = noOfAPI - 1
            if (noOfAPI === 0) {
                setLoading(false);
            }
            return Promise.reject(error);
        }
    )

    axiosInstance.interceptors.response.use(
        (response: any) => {
            noOfAPI = noOfAPI - 1
            if (noOfAPI === 0) {
                setLoading(false);
            }
            return response
        }, (error: any) => {
            noOfAPI = noOfAPI - 1
            if (noOfAPI === 0) {
                setLoading(false);
            }
            return Promise.reject(error);
        }
    )

    return (
        <>
            {loading && <div style={{ position: 'fixed', top: '0', left: '0', zIndex: 9999, background: 'rgba(0, 0, 0, 0.5)', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <HashLoader
                    color="#11b368"
                    size={70}
                />
            </div>}

        </>
    )

}

export default Loader