import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Input, Button, Table, Thead, Tbody, Tr, Th, Td, Flex, useToast, Text, GridItem, Grid, Select, FormControl, FormLabel } from "@chakra-ui/react";
import { GetPortfolioList, ReportInvestmentsDetails, ReportPayOutDetails } from "ApiRequestService";
import { dateFormatChange } from "commonfunction/CommonFunction";
import useCustomAlert from "components/alert/alerts";
import React, { useEffect, useState } from "react";
import { DecryptDataList } from "utils/encryption";
import * as XLSX from 'xlsx';  // Import XLSX for Excel export

const ReportView: React.FC = () => {
    const [report1fromDate, setReport1fromDate] = useState<string>("");
    const [report1toDate, setReport1toDate] = useState<string>("");
    const [report2Date, setReport2Date] = useState<string>("");
    const [report2Payout, setReport2Payout] = useState<string>("");
    const [report1Data, setReport1Data] = useState<any[]>([]);
    const [report2Data, setReport2Data] = useState<any[]>([]);
    const [report2Base64Data, setReport2Base64Data] = useState<string>("");
    const [loading1, setLoading1] = useState<boolean>(false);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [portfolio, setPortfolio] = useState<number>(null);
    const [portfolioList, setPortfolioList] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const toast = useToast();
    const { showAlert } = useCustomAlert();


    useEffect(() => {
        fetchPortfolioList()
    }, [])

    const handleGenerateReport = async (reportNumber: number) => {
        setReport1Data([]);
        setReport2Data([]);
        if (reportNumber === 1 && report1fromDate && report1toDate) {
            setLoading1(true);
            try {
                let data = {
                    from: report1fromDate,
                    to: report1toDate
                }
                const response = await ReportInvestmentsDetails(data);

                if (response && response.data && response.data.data.length > 0) {
                    const InvestmentDetails = DecryptDataList(response.data.data)
                    setReport1Data(InvestmentDetails);
                } else {
                    showAlert("info", "Info", "No Data Available for the selected Input");
                    console.log('No data found in response:', response);
                }
            } catch (error) {
                console.log('Error fetching data:', error);
            }
            setLoading1(false);
        } else if (reportNumber === 2 && report2Date && portfolio && report2Payout) {
            setLoading2(true);
            // Mock API fetch
            let formatdate = report2Date + '-' + report2Payout
            let date = dateFormatChange(formatdate)
            try {
                let data = {
                    date: date,
                    portfolio: portfolio,
                    payout: report2Payout
                }
                const response = await ReportPayOutDetails(data);

                if (response && response.data && response.data.data.length > 0) {
                    const PayoutDetails = DecryptDataList(response.data.data)
                    setReport2Base64Data(response.data.base64data)
                    setReport2Data(PayoutDetails);

                    console.log(PayoutDetails);
                } else {
                    showAlert("info", "Info", "No Data Available for the selected Input");

                    console.log('No data found in response:', response);
                }
            } catch (error) {
                console.log('Error fetching data:', error);
            }
            setLoading2(false);
        } else {
            showAlert("error", "Error", "Please Enter All Information");
        }
    };


    const handleDownload = (reportNumber: number) => {
        if (reportNumber === 1 && report1Data.length > 0) {
            exportToExcel('report1-table', 'Investment_Report');
        } else if (reportNumber === 2 && report2Data.length > 0) {
            //  let portfolioName =  portfolioList.filter(item => item.id == portfolio)
            downloadBase64File(report2Base64Data, "PayOut")
            // exportToExcel('report2-table', 'Pay_Out_Report');
        } else if (reportNumber === 3 && report2Data.length > 0) {
            //  let portfolioName =  portfolioList.filter(item => item.id == portfolio)
            exportJsonToExcel(report2Data, 'Pay_Out_Report_Details');
        } else {

            showAlert("warning", "Warning", "No data available for download.");
        }
    };

    const handleClear = (reportNumber: number) => {
        if (reportNumber === 1) {
            setReport1Data([]);
            setReport1fromDate("")
            setReport1toDate("")
            setErrorMessage('')
        } else if (reportNumber === 2) {
            setReport2Data([]);
            setReport2Date("")
            setReport2Payout("")
            setPortfolio(0)
        }
    }

    const validateDateRange = (fromDate: string | number, toDate: string | number) => {
        if (fromDate && toDate && fromDate >= toDate) {
            setErrorMessage("From Date must be earlier than To Date");
            // setReport1fromDate('')
            setReport1toDate('')
            setReport1Data([])
        } else {
            setErrorMessage("");
        }
    };

    // Handle changes in From Date
    const handleFromDateChange = (e: { target: { value: any; }; }) => {
        const newFromDate = e.target.value;
        setReport1fromDate(newFromDate);
        validateDateRange(newFromDate, report1toDate);
    };

    // Handle changes in To Date
    const handleToDateChange = (e: { target: { value: any; }; }) => {
        const newToDate = e.target.value;
        setReport1toDate(newToDate);
        validateDateRange(report1fromDate, newToDate);
    };

    // Excel Export Function
    const exportToExcel = (tableId: string, filename: string) => {
        const table = document.getElementById(tableId);
        if (table) {
            const workbook = XLSX.utils.table_to_book(table);
            XLSX.writeFile(workbook, `${filename}.xlsx`);
        }
    };


    const exportJsonToExcel = (jsonData: any[], filename: string) => {
        if (!Array.isArray(jsonData) || jsonData.length === 0) {
            console.error('Invalid or empty JSON data provided.');
            return;
        }

        // Create a worksheet from JSON data
        const worksheet = XLSX.utils.json_to_sheet(jsonData);

        // Process fields to ensure formatting (e.g., preserving leading zeros for account numbers)
        const headers = Object.keys(jsonData[0]); // Assuming all objects have the same keys
        const accountNumberField = 'account_number'; // Replace with your actual field name

        if (headers.includes(accountNumberField)) {
            // Determine the column letter for account_number
            const accountColIndex = headers.indexOf(accountNumberField);
            const accountColLetter = getColumnLetter(accountColIndex);

            // Iterate through rows to set account_number cells as strings
            for (let row = 1; row <= jsonData.length; row++) {
                const cellAddress = `${accountColLetter}${row + 1}`;
                const cell = worksheet[cellAddress];
                if (cell) {
                    cell.t = 's'; // Set cell type to string
                } else {
                    worksheet[cellAddress] = { t: 's', v: '' }; // Create cell if missing
                }
            }
        }

        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Export the workbook to an Excel file
        XLSX.writeFile(workbook, `${filename}.xlsx`);

        // Helper function to get Excel column letter from index
        function getColumnLetter(index: number): string {
            let temp = index + 1;
            let letter = '';
            while (temp > 0) {
                const mod = (temp - 1) % 26;
                letter = String.fromCharCode(65 + mod) + letter;
                temp = Math.floor((temp - mod) / 26);
            }
            return letter;
        }
    };

    const fetchPortfolioList = async () => {
        try {
            const response = await GetPortfolioList();
            if (response && response.data) {
                const PortfolioList = response.data.data;  // Adjust as per actual response structure
                if (PortfolioList) {
                    setPortfolioList(PortfolioList)
                }
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const downloadBase64File = (base64Data: any, fileName: string) => {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
        link.download = fileName + '.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <Box pt={{ base: '70px' }} position="relative" height="90vh" overflow="hidden">
            <Tabs>
                <TabList>
                    <Tab width={'50%'}>Investments Details Report</Tab>
                    <Tab width={'50%'}>Pay-Out Details Report</Tab>
                </TabList>

                <TabPanels>
                    {/* Report 1 Tab */}
                    <TabPanel>
                        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
                            {/* Label */}
                            <GridItem colSpan={3} display="flex" alignItems="center">
                                <Text>Please Select Date Range :</Text>
                            </GridItem>

                            {/* From Date Input */}
                            <GridItem colSpan={3}>
                                <Input
                                    type="date"
                                    value={report1fromDate}
                                    onChange={handleFromDateChange}
                                    placeholder="Enter From Date..."
                                />
                            </GridItem>

                            {/* To Date Input */}
                            <GridItem colSpan={3}>
                                <Input
                                    type="date"
                                    value={report1toDate}
                                    onChange={handleToDateChange}
                                    placeholder="Enter To Date..."
                                />
                            </GridItem>

                            {/* Buttons */}
                            <GridItem colSpan={3} display="flex" justifyContent="flex-start">
                                <Button
                                    colorScheme="blue"
                                    onClick={() => handleGenerateReport(1)}
                                    disabled={(!report1fromDate || !report1toDate || report1fromDate >= report1toDate || loading1)}
                                    mr={2}
                                >
                                    {loading1 ? "Generating..." : "Generate"}
                                </Button>

                                <Button
                                    colorScheme="green"
                                    onClick={() => handleDownload(1)}
                                    disabled={!report1Data.length}
                                    mr={2}
                                >
                                    Download
                                </Button>

                                <Button
                                    colorScheme="yellow"
                                    onClick={() => handleClear(1)}
                                    disabled={!report1Data.length}
                                >
                                    Clear
                                </Button>
                            </GridItem>

                            {/* Error Message */}
                            {errorMessage && (
                                <GridItem colSpan={12}>
                                    <Box color="red.500" mt={2}>
                                        {errorMessage}
                                    </Box>
                                </GridItem>
                            )}
                        </Grid>
                        {report1Data.length > 0 && (
                            <Box maxH="65vh" overflowY="auto">
                                <Table variant="simple" id="report1-table">
                                    <Thead>
                                        <Tr>
                                            <Th>Investor ID</Th>
                                            <Th>Investment ID</Th>
                                            <Th>Investor Name</Th>
                                            <Th>Amount</Th>
                                            <Th>Invested Date</Th>
                                            <Th>End Date</Th>
                                            <Th>Investor Percentage</Th>
                                            <Th>Investor Percentage Amount</Th>
                                            <Th>Portfolio Name</Th>
                                            <Th>Portfolio Percent</Th>
                                            <Th>Portfolio Percentage Amount</Th>
                                            <Th>Sub Marketer Name</Th>
                                            <Th>Sub Marketer Percent</Th>
                                            <Th>Sub Marketer Percentage Amount</Th>
                                            <Th>Status</Th>
                                            <Th>Phone</Th>
                                            <Th>Email</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {report1Data.map((item) => (
                                            <Tr key={item.investor_investment_id}>
                                                <Td>{item.investor_id}</Td>
                                                <Td>{item.investor_investment_id}</Td>
                                                <Td>{`${item.firstname} ${item.lastname}`}</Td>
                                                <Td>{item.amount}</Td>
                                                <Td>{new Date(item.invested_date).toLocaleDateString()}</Td>
                                                <Td>{new Date(item.end_date).toLocaleDateString()}</Td>
                                                <Td>{item.investor_percentage}</Td>
                                                <Td>{item.investor_percentage_amount}</Td>
                                                <Td>{item.portfolio_name}</Td>
                                                <Td>{item.portfolio_percent}</Td>
                                                <Td>{item.portfolio_percentage_amount}</Td>
                                                <Td>{item.sub_marketer_name}</Td>
                                                <Td>{item.sub_marketer_percent}</Td>
                                                <Td>{item.sub_marketer_percentage_amount}</Td>
                                                <Td>{item.status}</Td>
                                                <Td>{item.phone}</Td>
                                                <Td>{item.email}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                        )}

                    </TabPanel>

                    {/* Report 2 Tab */}
                    <TabPanel>
                        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
                            {/* Label */}
                            <GridItem colSpan={3} display="flex" alignItems="center">
                                <Input
                                    type="month"
                                    value={report2Date}
                                    onChange={(e) => setReport2Date(e.target.value)}
                                    placeholder="Enter Date"
                                    mr={2}
                                />
                            </GridItem>

                            {/* From Date Input */}
                            <GridItem colSpan={2}>
                                <Select placeholder='Please select Payout...' value={report2Payout}
                                    onChange={(e) => setReport2Payout(e.target.value)}>
                                    <option value="10">1st </option>
                                    <option value="20">2nd</option>
                                    <option value="30">3rd</option>
                                </Select>
                            </GridItem>

                            {/* To Date Input */}
                            <GridItem colSpan={3}>
                                <Select placeholder='Please select Portfolio...'
                                    value={portfolio}
                                    onChange={(e) => setPortfolio(Number(e.target.value))}>
                                    {portfolioList.map(item => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Select>
                            </GridItem>

                            {/* Buttons */}
                            <GridItem colSpan={4} display="flex" justifyContent="flex-start">
                                <Button
                                    colorScheme="blue"
                                    onClick={() => handleGenerateReport(2)}
                                    disabled={(!report2Date || loading2)}
                                    mr={2}
                                >
                                    {loading2 ? "Generating..." : "Generate"}
                                </Button>

                                <Button
                                    colorScheme="green"
                                    onClick={() => handleDownload(3)}
                                    disabled={!report2Data.length}
                                    mr={2}
                                > Download

                                </Button>
                                <Button
                                    colorScheme="pink"
                                    onClick={() => handleDownload(2)}
                                    disabled={!report2Data.length}
                                    mr={2}
                                >
                                    Pay Out
                                </Button>

                                <Button
                                    colorScheme="yellow"
                                    onClick={() => handleClear(2)}
                                    disabled={!report2Data.length}
                                >
                                    Clear
                                </Button>
                            </GridItem>

                        </Grid>

                        {report2Data.length > 0 && (
                            <Box maxH="65vh" overflowY="auto">
                                <Table variant="simple" id="report2-table">
                                    <Thead>
                                        <Tr>
                                            <Th>Investor ID</Th>
                                            <Th>Account Holder</Th>
                                            <Th>Account Number</Th>
                                            <Th>Amount</Th>
                                            <Th>Investor Percentage</Th>
                                            <Th>Bank Name</Th>
                                            <Th>Branch Name</Th>
                                            <Th>Email</Th>
                                            <Th>IFSC Code</Th>
                                            <Th>Aadhar Number</Th>
                                            <Th>Pan Number</Th>
                                            <Th>Is TDS?</Th>
                                            <Th>Address</Th>
                                            {/* <Th>PayOut Start Date</Th>
                                            <Th>PayOut End Date</Th> */}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {report2Data.map((item) => (
                                            <Tr key={item.investor_id}>
                                                <Td>{item.investor_id}</Td>
                                                <Td>{(item.account_holder).toUpperCase()}</Td>
                                                <Td>{item.account_number}</Td>
                                                <Td>{item.Amount}</Td>
                                                <Td>{item.investor_percentage}</Td>
                                                <Td>{item.bank_name}</Td>
                                                <Td>{item.branch_name}</Td>
                                                <Td>{item.email}</Td>
                                                <Td>{item.ifsc_code}</Td>
                                                <Td>{item.aadar_number}</Td>
                                                <Td>{item.pan_number}</Td>
                                                <Td>{(item.is_tds == 1 ? 'Yes' : 'NO')}</Td>
                                                <Td>{item.address}</Td>
                                                {/* <Td>{new Date(item.first_pay_date).toLocaleDateString()}</Td>
                                                <Td>{new Date(item.last_pay_date).toLocaleDateString()}</Td> */}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export { ReportView };
