// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';

// Custom components
import Card from 'components/card/Card';
//import { barChartDataDailyTraffic, barChartOptionsDailyTraffic } from 'variables/charts';

// Assets
import { RiArrowUpSFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { GetMDBWeekChartData } from 'ApiRequestService';

export default function DailyTraffic(props: { [x: string]: any }) {
	const { ...rest } = props;
	const [data1 , setData1] = useState([])
	const [data2 , setData2] = useState([])

	useEffect(()=>{
		fetcDailyData()
	},[])

	const fetcDailyData = async () => {
		try {
		  const response = await GetMDBWeekChartData();
		  if (response && response.data) {
			const dashboardData = response.data.data;  // Adjust as per actual response structure
			if (dashboardData.length > 0) {
				for(let i=0;i<dashboardData.length;i++){
					data1.push(dashboardData[i].amount)
					data2.push(dashboardData[i].invested_date)
				}
			}
		  } else {
			console.log('No data found in response:', response);
		  }
		} catch (error) {
		  console.log('Error fetching data:', error);
		}
	  }

	const barChartDataDailyTraffic = [
		{
			name: 'Daily Investments',
			data: data1
		}
	];
	
	const barChartOptionsDailyTraffic: any = {
		chart: {
			toolbar: {
				show: false
			}
		},
		tooltip: {
			style: {
				fontSize: '12px',
				fontFamily: undefined
			},
			onDatasetHover: {
				style: {
					fontSize: '12px',
					fontFamily: undefined
				}
			},
			theme: 'dark'
		},
		xaxis: {
			categories: data2,
			show: false,
			labels: {
				show: true,
				style: {
					colors: '#A3AED0',
					fontSize: '14px',
					fontWeight: '500'
				}
			},
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false
			}
		},
		yaxis: {
			show: false,
			color: 'black',
			labels: {
				show: true,
				style: {
					colors: '#CBD5E0',
					fontSize: '14px'
				}
			}
		},
		grid: {
			show: false,
			strokeDashArray: 5,
			yaxis: {
				lines: {
					show: true
				}
			},
			xaxis: {
				lines: {
					show: false
				}
			}
		},
		fill: {
			type: 'gradient',
			gradient: {
				type: 'vertical',
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				colorStops: [
					[
						{
							offset: 0,
							color: '#4318FF',
							opacity: 1
						},
						{
							offset: 100,
							color: 'rgba(67, 24, 255, 1)',
							opacity: 0.28
						}
					]
				]
			}
		},
		dataLabels: {
			enabled: false
		},
		plotOptions: {
			bar: {
				borderRadius: 10,
				columnWidth: '40px'
			}
		}
	};

	
console.log(barChartDataDailyTraffic)
console.log(barChartOptionsDailyTraffic)
	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<Card alignItems='center' flexDirection='column' w='100%' {...rest}>
			<Flex justify='space-between' align='start' px='10px' pt='5px' w='100%'>
				<Flex flexDirection='column' align='start' me='20px'>
					<Flex w='100%'>
						<Text me='auto' color='secondaryGray.600' fontSize='sm' fontWeight='500'>
							Daily Investments
						</Text>
					</Flex>
					{/* <Flex align='end'>
						<Text color={textColor} fontSize='34px' fontWeight='700' lineHeight='100%'>
							2.579
						</Text>
						<Text ms='6px' color='secondaryGray.600' fontSize='sm' fontWeight='500'>
							Visitors
						</Text>
					</Flex> */}
				</Flex>
				{/* <Flex align='center'>
					<Icon as={RiArrowUpSFill} color='green.500' />
					<Text color='green.500' fontSize='sm' fontWeight='700'>
						+16.45%
					</Text>
				</Flex> */}
			</Flex>
			<Box h='240px' mt='auto'>
				<BarChart chartData={barChartDataDailyTraffic} chartOptions={barChartOptionsDailyTraffic} />
			</Box>
		</Card>
	);
}
