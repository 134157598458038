import {
  Box,
  Text,
  Image,
  Stat,
  StatLabel,
  SimpleGrid,
  Icon,
  useColorModeValue,
  GridItem,
  Flex,
  Avatar,
} from '@chakra-ui/react';
import { DashboardDataInvestment } from 'ApiRequestService';
import { dateFormatddmmyy, reverseDateFormat } from 'commonfunction/CommonFunction';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { useEffect, useState } from 'react';
import { BsBoxArrowInUpRight, BsFillGeoAltFill, BsFillTelephoneFill } from 'react-icons/bs';
import { MdBarChart, MdAttachMoney, MdAddTask, MdFileCopy, MdEmail, MdCalendarMonth, MdMonetizationOn, MdOutlineNavigateNext, MdArrowOutward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { DecryptObjectData } from 'utils/encryption';

interface ProfileCardProps {
  name: string;
  total_invested: number;
  total_investments_count: number;
  phone: string;
  email: string;
  address: string;
  next_payout: number;
  next_payout_date: string;
  total_payout: number;
}

export const ProfileCard: React.FC = () => {
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const borderColor = 'gray.300';

  const navigate = useNavigate();
  const [investorData, setInvestorData] = useState<ProfileCardProps>({
    name: '',
    total_invested: null,
    total_investments_count: null,
    phone: '',
    email: '',
    address: '',
    next_payout: null,
    next_payout_date: '',
    total_payout: null,

  })

  useEffect(() => {
    sessionStorage.setItem('pageName', 'Investor Dashboard')
    const investor_ID = sessionStorage.getItem('investor_ID');
    fetchDashboardData(investor_ID)
  }, [])

  const fetchDashboardData = async (investor_ID: any) => {
    try {
      const response = await DashboardDataInvestment({ id: investor_ID });
      if (response && response.data) {
        const dashboardData = DecryptObjectData(response.data.data[0]);  // Adjust as per actual response structure
        if (dashboardData) {
          // dashboardData.total_invested = parseInt(dashboardData.total_invested)
          dashboardData.next_payout_date = dashboardData.next_payout_date ? dateFormatddmmyy(dashboardData.next_payout_date) : "N/A"
          setInvestorData(dashboardData)
        }
      } else {
        console.log('No data found in response:', response);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  }

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const navigateInvestor = () => {
    sessionStorage.setItem('isAddBank', 'false')
    navigate('/admin/investor-view');
  }

  return (
    <SimpleGrid columns={{ base: 1, md: 6 }} spacing="20px" height="100%" mb="20px">
      {/* Profile Card takes 30% of the width */}
      <GridItem colSpan={{ base: 1, md: 2 }} width="100%" height="100%">
        <Box
          borderRadius="lg"
          boxShadow="2xl"
          p={2}
          height="100%"
          display="flex"
          flexDirection="column"
          border="1px"
          borderColor={borderColor}
        >
          <Box position="absolute" cursor="pointer">
            <BsBoxArrowInUpRight width={10} height={10} title='Navigate to Investor' onClick={navigateInvestor} />
            {/* <Icon as={MdArrowOutward} w={6} h={6} title='Navigate to Investor' onClick={navigateInvestor}/> */}
          </Box>
          {/* <Image
            borderRadius="full"
            boxSize="100px"
            // src="https://thispersondoesnotexist.com/"
            alt="Profile Image"
            mx="auto"
          /> */}
          <Avatar
            name={investorData.name}
            size="lg"
            mr="4"  mx="auto"   borderRadius="full"
            boxSize="100px"
          />
          <Text fontSize="xl" textAlign="center" fontWeight="bold" mt={2}>{investorData.name}</Text>
          <Flex mt={4} justifyContent="space-arround" px={4}>
            <Flex alignItems="center">
              <Icon as={BsFillTelephoneFill} mr={2} />
              <Text>{investorData.phone}</Text>
            </Flex>
          </Flex>
          <Flex mt={4} justifyContent="space-arround" px={4}>
            <Flex alignItems="center">
              <Icon as={MdEmail} mr={2} />
              <Text>{investorData.email}</Text>
            </Flex>
          </Flex>
          <Flex mt={4} justifyContent="space-arround" px={4}>
            <Flex alignItems="center">
              <Icon as={BsFillGeoAltFill} mr={2} />
              <Text>{investorData.address}</Text>
            </Flex>
          </Flex>
        </Box>
      </GridItem>

      {/* Remaining 4 cards in a 2x2 grid */}
      <GridItem colSpan={{ base: 1, md: 4 }}>
        <SimpleGrid columns={{ base: 2, md: 2 }} spacing="20px">
          <MiniStatistics
            startContent={
              <IconBox
                w='30px'
                h='30px'
                bg={boxBg}
                icon={<Icon w='32px' h='32px' as={MdMonetizationOn} color={brandColor} />}
              />
            }
            name='Total Investments'
            value={formatCurrency(investorData.total_invested)}
            border="1px"
            borderColor={borderColor}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='30px'
                h='30px'
                bg={boxBg}
                icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
              />
            }
            name='Total Paid Out'
            value={formatCurrency(investorData.total_payout)}
            border="1px"
            borderColor={borderColor}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='30px'
                h='30px'
                bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
              />
            }
            name='Active Investments'
            value={investorData?.total_investments_count ? investorData.total_investments_count : 0}
            border="1px"
            borderColor={borderColor}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='30px'
                h='30px'
                bg={boxBg}
                icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
              />
            }
            name='Next Pay Out Amount'
            value={formatCurrency(investorData.next_payout)}
            border="1px"
            borderColor={borderColor}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='30px'
                h='30px'
                bg={boxBg}
                icon={<Icon w='32px' h='32px' as={MdCalendarMonth} color={brandColor} />}
              />
            }
            name='Next Pay Out Date'
            value={investorData.next_payout_date}
            border="1px"
            borderColor={borderColor}
          />
        </SimpleGrid>
      </GridItem>
    </SimpleGrid>
  );
};
