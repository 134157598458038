import React, { FC, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Input,
    useColorModeValue,
    Flex,
    Avatar,
} from '@chakra-ui/react';
import { CreateInvestor, PortfolioListAll } from 'ApiRequestService';
import { useNavigate } from 'react-router-dom';
import {
    BsPencilSquare,
    BsBarChartLineFill,
    BsPersonFillAdd,
} from 'react-icons/bs';
import { DecryptDataList, EncryptDataList } from 'utils/encryption';

import { dateFormatChange } from 'commonfunction/CommonFunction';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
interface portfolio {
    email: string;
    portfolio_name: string;
    investor_count: any;
    portfolio_owner_id: any;
    phone: any;
    total_investment_amount: string;
}



// Custom cell renderer component
const ActionRenderer: FC<{ value: any; data: portfolio; context: any }> = ({ data, context }) => {
    const { navigate, setShowAggrement, setSelectedInvestmentId } = context;

    const handleDashboardAction = (id: string) => {
        sessionStorage.setItem('portfolio_ID', id);
        navigate('/admin/portfolio-dashboard');
    };

    return (
        <>
            <Button
                style={{ width: '50px', margin: '1px' }}
                colorScheme="pink"
                title="Dashboard"
                onClick={() => handleDashboardAction(data.portfolio_owner_id)}
            >
                <BsBarChartLineFill />
            </Button>
        </>
    );
};

const PortfolioList: React.FC = () => {
    const [portfolioList, setPortfolioList] = useState<portfolio[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAllPortfolio();
    }, []);

    // Fetch investors
    const fetchAllPortfolio = async () => {
        try {
            const response = await PortfolioListAll();
            console.log(response);

            if (response && response.data) {
                const InvestorList = response.data.data as portfolio[]
                setPortfolioList(InvestorList);
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

 

    // Format currency
    const formatCurrency = (amount: string) => {
        if (amount) {
            const numberAmount = parseFloat(amount.replace(/,/g, '')); // Remove commas if any and convert to number
            return new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR',
                maximumFractionDigits: 0,
            }).format(numberAmount);
        }
    };

    // Define columns for react-table
    const columnDefs:ColDef[] = [
        {
            headerName: 'ID', sortable: true, filter: true,
            field: 'portfolio_owner_id', width:100
        },
        {
            headerName: 'Portfolio Name', sortable: true, filter: true,
            field: 'portfolio_name',
            cellRenderer: (params:any) => (
                <Flex align="center">
                    <Avatar
                        name={`${params.data.portfolio_name} `}
                        src={`https://robohash.org/${params.getValue()}?set=set5`}
                        size="sm"
                        mr="4"
                    />
                    <span>{`${params.data.portfolio_name}`}</span>
                </Flex>
            ),
        },
        {
            headerName: 'Email', sortable: true, filter: true,
            field: 'email',
        },
        {
            headerName: 'Phone', sortable: true, filter: true,
            field: 'phone',
        },
        {
            headerName: 'Total Investments Raised', sortable: true, filter: true,
            field: 'total_investment_amount',
            valueFormatter: (params: { value: string; }) => formatCurrency(params.value), // You can format this based on your formatCurrency function
        },
        {
            headerName: 'Total Investor', sortable: true, filter: true,
            field: 'investor_count',
        },
        {
            headerName: 'Actions', width: 100,
            field: 'actions', pinned: "right", lockPinned: true,sortable: false, filter: false ,
            cellClass: "lock-pinned",
            cellRenderer: 'actionRenderer', // Use the custom cell renderer
            cellRendererParams: {
                context: {
                    navigate,
                }
            }
        },
    ];



    const agGridTheme = useColorModeValue('ag-theme-alpine', 'ag-theme-alpine-dark');

    // Styles based on theme
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    return (
        <Box
            pt={{ base: '70px' }}
            position="relative"
            height="93vh"
            overflow="hidden" className={agGridTheme}
        >
            <Box
                position="relative"
                height="calc(92vh - 20px - 48px)" // Adjust height based on header and actions button
                overflow="hidden"
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    overflowX="auto"
                    overflowY="auto"
                    padding="0"
                    border="1px"
                    borderColor={borderColor}
                >
                    <AgGridReact
                        rowHeight={50}
                        rowData={portfolioList}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={50}
                        domLayout="normal"
                        animateRows={true}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            resizable: true,
                        }}
                        components={{
                            actionRenderer: ActionRenderer // Register the custom cell renderer
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export { PortfolioList };
