import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    Grid,
    GridItem,
    VStack
} from '@chakra-ui/react';
import { useForm, useWatch } from 'react-hook-form';
import { FormFooter } from 'components/footer/FormFooter';
import { CreateInvestment, UpdateInvestment, DeleteInvestment, GetInvestment, GetInvestorBank, GetPortfolioList, GetSubMarketerList, GetReceiverBank } from '../../../ApiRequestService';
import { InvestorInvestment } from '../FormModels';
import { useNavigate } from 'react-router-dom';
import { dateFormatChange, fetchUserName, getIsAdmin, reverseDateFormat } from 'commonfunction/CommonFunction';
import useCustomAlert from 'components/alert/alerts';
import { DecryptDataList } from 'utils/encryption';


interface DropdownOption {
    id: number;
    name: string;
}



interface BankDropdownOption {
    id: number;
    name: string;
    bank_name: string;
    account_number: string;
}

const InvestmentView: React.FC = () => {
    const { register, handleSubmit, reset, setValue, formState: { errors }, control } = useForm<InvestorInvestment>();
    const [formData, setFormData] = useState<any>([]);

    const { showAlert, showDeleteConfirmation, ConfirmationDialog, showSaveConfirmation } = useCustomAlert();
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [currentInvestmentId, setCurrentInvestmentId] = useState<number | null>(null);

    const currentPath = window.location.pathname;


    const [investorBankList, setInvestorBankList] = useState<BankDropdownOption[]>([]);
    const [portfolioList, setPortfolioList] = useState<DropdownOption[]>([]);
    const [subMarketerList, setSubMarketerList] = useState<DropdownOption[]>([]);
    const [receiverBankList, setReceiverBankList] = useState<DropdownOption[]>([]);
    const navigateback = sessionStorage.getItem('investmentNavigate');
    const [isInvestedDateChanged, setIsInvestedDateChanged] = useState(false);

    const isAdmin = getIsAdmin()

    const [isReadonly, setIsReadOnly] = useState(false)


    const navigate = useNavigate();
    useEffect(() => {

        sessionStorage.setItem('pageName', 'Investment Details')
        const investor_ID = sessionStorage.getItem('investor_ID');
        if (currentPath.includes('admin/investment-view')) {
            const investmentID = sessionStorage.getItem('investor_investment_id');
            if (investmentID) {
                setShowDelete(isAdmin)
                setCurrentInvestmentId(parseInt(investmentID));
                setIsReadOnly(!isAdmin)
                fetchInvestment(investmentID);
            }
        }
        fetchBankDropDowns(investor_ID)
        fetchPortfolioList()
        fetchReceiverBankList()
    }, [currentPath]);

    // Fetch investment data if ID is present
    const fetchInvestment = async (investmentID: string) => {
        try {
            const response = await GetInvestment({ id: investmentID });
            if (response && response.data) {
                const investment = response.data.data;  // Adjust as per actual response structure
                if (investment) {
                    investment.invested_date = reverseDateFormat(investment.invested_date)
                    investment.end_date = reverseDateFormat(investment.end_date)
                    investment.created_at = reverseDateFormat(investment.created_at)
                    investment.is_deleted = investment.is_deleted
                    fetchSubMarketer(investment.portfolio_owner_id)
                    setFormData(investment)
                    console.log(formData)
                    // Set the form values using the API response
                    reset(investment);  // Reset the form with fetched data
                }
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const fetchBankDropDowns = async (investorID: string) => {
        try {
            const response = await GetInvestorBank({ id: investorID });
            if (response && response.data) {
                const decryptedBankList = DecryptDataList(response.data.data) as BankDropdownOption[];  // Adjust as per actual response structure
                if (decryptedBankList) {
                    // Map through the decrypted bank list and format the name
                    const formattedBankList = decryptedBankList.map(bank => ({
                        id: bank.id,  // Assuming each bank has an `id`
                        name: `${bank.bank_name} - XXXX${bank.account_number.slice(-5)}`,
                        bank_name: bank.bank_name,
                        account_number: bank.account_number
                    }));

                    // Set the formatted list in state
                    setInvestorBankList(formattedBankList);
                }
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };
    const fetchPortfolioList = async () => {
        try {
            const response = await GetPortfolioList();
            if (response && response.data) {
                const PortfolioList = response.data.data;  // Adjust as per actual response structure
                if (PortfolioList) {
                    setPortfolioList(PortfolioList)
                }
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };
    const fetchSubMarketer = async (portfolioID: string) => {
        try {
            const response = await GetSubMarketerList({ id: portfolioID });
            if (response && response.data) {
                const SubMarketer = response.data.data;  // Adjust as per actual response structure
                if (SubMarketer) {
                    setSubMarketerList(SubMarketer)
                }
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const fetchReceiverBankList = async () => {
        try {
            const response = await GetReceiverBank();
            if (response && response.data) {
                const ReceiverBankList = response.data.data;  // Adjust as per actual response structure
                if (ReceiverBankList) {
                    const decryptedBankList = DecryptDataList(ReceiverBankList) as BankDropdownOption[];  // Adjust as per actual response structure
                    // Map through the decrypted bank list and format the name
                    const formattedBankList = decryptedBankList.map(bank => ({
                        id: bank.id,  // Assuming each bank has an `id`
                        name: bank.bank_name,
                    }));
                    setReceiverBankList(formattedBankList)
                }
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    // Submit form data (Create or Update)
    const onSubmit = async (data: InvestorInvestment) => {
        try {
            data.invested_date = dateFormatChange(data.invested_date)
            data.end_date = dateFormatChange(data.end_date)
            const InvestorID = sessionStorage.getItem('investor_ID');
            data.investor_id = InvestorID ? Number(InvestorID) : null;
            data.investor_account_id = Number(data.investor_account_id)
            data.is_deleted = false;
            data.portfolio_owner_id = Number(data.portfolio_owner_id)
            data.pet_account_id = Number(data.pet_account_id)
            data.sub_marketer_id = data.sub_marketer_id ? Number(data.sub_marketer_id) : null
            data.sub_marketer_percent = data.sub_marketer_percent ? Number(data.sub_marketer_percent) : null
            data.payout_date = data.payout_date ? Number(data.payout_date) : null
            data.is_renewal = Number(data.is_renewal)
            data.is_tds = Number(data.is_tds)
            let response;
            if (currentInvestmentId !== null) {
                // Update existing investment
                // data.investor_id = currentInvestorId.toString();  // Ensure ID is set
                response = await UpdateInvestment(data);
                if (response.status === 200) {
                    navigate(`/admin/${navigateback}`)

                    showAlert("success", "Success", "Investments Updated Successfully!");
                } else {
                    showAlert("error", "Error", "Failed to Update Investments");
                }
            } else {
                 showSaveConfirmation(async (result) => {
                     if (result) {
                        // Create new investment  
                        data.created_by = fetchUserName()
                        data.created_at = dateFormatChange(new Date())
                        response = await CreateInvestment(data);
                        if (response.status === 200) {
                            navigate(`/admin/${navigateback}`)
                            showAlert("success", "Success", "Investments Saved Successfully!");
                        } else {
                            showAlert("error", "Error", "Failed to Save Investments");
                        }
                    }
                })
            }

        } catch (error) {
            console.log('Error during form submission:', error);
        }
    };

    // Handle delete investor
    const handleDeleteInvestment = async () => {
        if (currentInvestmentId !== null) {

            showDeleteConfirmation(async (result) => {
                if (result) {
                    try {
                        const response = await DeleteInvestment({ id: currentInvestmentId });

                        if (response.status === 200) {
                            navigate(`/admin/${navigateback}`)
                            showAlert("success", "Deleted", "Investments Deleted Successfully!");
                        } else {
                            showAlert("error", "Error", "Failed to Delete Investments");
                        }
                    } catch (error) {
                        console.log('Error deleting investment:', error);
                    }
                } else {
                    showAlert("info", "Cancelled", "Delete operation cancelled.");
                }
            });

        }
    };

    // Watch the invested_date field
    const investedDate = useWatch({
        control,
        name: 'invested_date', // Field to watch
    });

    // Automatically set the end_date when invested_date changes
    useEffect(() => {
        if (investedDate  && isInvestedDateChanged) {
            const investedDateObj = new Date(investedDate);
            const endDateObj = new Date(investedDateObj);
            endDateObj.setFullYear(investedDateObj.getFullYear() + 1); // Add 1 year
            setValue('end_date', endDateObj.toISOString().split('T')[0]); // Set the calculated end_date

            const payout = Number(calculatePayoutDate(investedDate))
            setValue('payout_date', payout)
        }
    }, [investedDate,isInvestedDateChanged, setValue]);


    const handleInputChange = (fieldName: keyof InvestorInvestment, value: any) => {
        setFormData((prevData: any) => ({
            ...prevData,
            [fieldName]: value,
        }));

        if (fieldName === 'portfolio_owner_id') {
            fetchSubMarketer(value)
        }

    };

    const calculatePayoutDate = (investedDate: string | number | Date) => {
        if (!investedDate) return '';

        const date = new Date(investedDate);
        const day = date.getDate();

        if (day == 30 || day == 31 || (day >= 1 && day <= 9)) {
            return 10;
        } else if (day >= 10 && day <= 19) {
            return 20;
        } else if (day >= 20 && day <= 29) {
            return 30;
        }
    };

    return (
        <Box pt={{ base: '90px' }} mb={'60px'}>
            <Box
                border="1px"
                borderColor="gray.400"
                borderRadius="md"
                p={4}
                boxShadow="lg"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={1}>
                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} w='100%'>

                            {/* Amount */}
                            <GridItem>
                                <FormControl isRequired isReadOnly={isReadonly}>
                                    <FormLabel>Amount</FormLabel>
                                    <Input type="number"  {...register('amount', { required: true })} />
                                    {errors.amount && <p style={{ color: 'red' }}>This field is required</p>}
                                </FormControl>
                            </GridItem>
                            {/* Invested Date */}
                            <GridItem>
                                <FormControl isRequired isReadOnly={isReadonly}>
                                    <FormLabel>Invested Date</FormLabel>
                                    <Input type="date"{...register('invested_date', { required: true , onChange:() => setIsInvestedDateChanged(true)} )} />
                                    {errors.invested_date && <p style={{ color: 'red' }}>This field is required</p>}
                                </FormControl>
                            </GridItem>

                            {/* End Date */}
                            <GridItem>
                                <FormControl isReadOnly={isReadonly}>
                                    <FormLabel>End Date</FormLabel>
                                    <Input type="date" {...register('end_date')} />
                                    {/* {errors.end_date && <p style={{ color: 'red' }}>This field is required</p>} */}
                                </FormControl>
                            </GridItem>


                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>Lender Bank</FormLabel>
                                    <Select placeholder='Please select...'{...register('investor_account_id', { required: true })}
                                        value={formData.investor_account_id} disabled={isReadonly}
                                        onChange={(e) => handleInputChange('investor_account_id', Number(e.target.value))}>
                                        {investorBankList.map(item => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                    {errors.investor_account_id && <p style={{ color: 'red' }}>This field is required</p>}
                                </FormControl>
                            </GridItem>

                            {/* Portfolio Owner ID */}
                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>Portfolio</FormLabel>
                                    <Select placeholder='Please select...'{...register('portfolio_owner_id', { required: true })}
                                        value={formData.portfolio_owner_id} disabled={isReadonly}
                                        onChange={(e) => handleInputChange('portfolio_owner_id', Number(e.target.value))}>
                                        {portfolioList.map(item => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                    {errors.portfolio_owner_id && <p style={{ color: 'red' }}>This field is required</p>}
                                </FormControl>
                            </GridItem>

                            {/* Sub Marketer ID */}
                            <GridItem>
                                <FormControl isDisabled={!formData.portfolio_owner_id}>
                                    <FormLabel>Sub Marketer</FormLabel>
                                    <Select placeholder='Please select...'{...register('sub_marketer_id')}
                                        value={formData.sub_marketer_id} disabled={isReadonly}
                                        onChange={(e) => handleInputChange('sub_marketer_id', Number(e.target.value))}>
                                        {subMarketerList.map(item => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridItem>

                            {/* Investor Percentage */}
                            <GridItem>
                                <FormControl isRequired isReadOnly={isReadonly}>
                                    <FormLabel>Investor Percentage</FormLabel>
                                    <Input max={30} type="number" step="0.1" {...register('investor_percentage', { required: true })} />
                                    {errors.investor_percentage && <p style={{ color: 'red' }}>This field is required</p>}
                                </FormControl>
                            </GridItem>

                            {/* Portfolio Percent */}
                            <GridItem>
                                <FormControl isRequired isReadOnly={isReadonly}>
                                    <FormLabel>Portfolio Percent</FormLabel>
                                    <Input max={30} type="number" step="0.1" {...register('portfolio_percent', { required: true })} />
                                    {errors.portfolio_percent && <p style={{ color: 'red' }}>This field is required</p>}
                                </FormControl>
                            </GridItem>

                            {/* Sub Marketer Percent */}
                            <GridItem>
                                <FormControl isDisabled={!formData.sub_marketer_id} isReadOnly={isReadonly}>
                                    <FormLabel>Sub Marketer Percent</FormLabel>
                                    <Input max={30} type="number" step="0.1" {...register('sub_marketer_percent')} />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>Pay Out Date</FormLabel>
                                    <Select placeholder='Please select...' {...register('payout_date', { required: true })} disabled={isReadonly}>
                                        <option value="10">10 th </option>
                                        <option value="20">20 th</option>
                                        <option value="30">30 th</option>
                                        {errors.payout_date && <p style={{ color: 'red' }}>This field is required</p>}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>Borrower Bank</FormLabel>
                                    <Select placeholder='Please select...'{...register('pet_account_id', { required: true })} disabled={isReadonly}
                                        value={formData.pet_account_id}  defaultValue="1"
                                        onChange={(e) => handleInputChange('pet_account_id', Number(e.target.value))}>
                                        {/* {receiverBankList.map(item => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))} */}
                                         <option value="1">HDFC</option>
                                    </Select>
                                    {errors.pet_account_id && <p style={{ color: 'red' }}>This field is required</p>}
                                </FormControl>
                            </GridItem>

                            {/* Cheque Number */}
                            <GridItem>
                                <FormControl isRequired isReadOnly={isReadonly}>
                                    <FormLabel>Cheque Number</FormLabel>
                                    <Input type="text" {...register('cheque_number', { required: true })} />
                                    {errors.cheque_number && <p style={{ color: 'red' }}>This field is required</p>}
                                </FormControl>
                            </GridItem>

                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>Status</FormLabel>
                                    <Select placeholder='Please select...' defaultValue="Active" {...register('status', { required: true })} disabled={isReadonly}>
                                        <option value="Active">Active</option>
                                        <option value="Closed">Closed</option>
                                        {errors.status && <p style={{ color: 'red' }}>This field is required</p>}
                                    </Select>
                                </FormControl>
                            </GridItem>                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>Is Renewal</FormLabel>
                                    <Select placeholder='Please select...' defaultValue='0' {...register('is_renewal', { required: true })} disabled={isReadonly}>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                        {errors.status && <p style={{ color: 'red' }}>This field is required</p>}
                                    </Select>
                                </FormControl>
                            </GridItem>                            <GridItem>
                                <FormControl isRequired>
                                    <FormLabel>Is TDS</FormLabel>
                                    <Select placeholder='Please select...' defaultValue='1'  {...register('is_tds', { required: true })} disabled={isReadonly}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                        {errors.status && <p style={{ color: 'red' }}>This field is required</p>}
                                    </Select>
                                </FormControl>
                            </GridItem>
                        </Grid>

                        {/* Footer with Submit and Delete Buttons */}
                        <FormFooter
                            createdBy={formData.created_by}
                            modifiedBy={''}
                            createddate={formData.created_at}
                            modifieddate={''}
                            buttonName={'Submit'}
                            navigatebackURL={`/admin/${navigateback}`}
                            disabled={isReadonly}
                            onDelete={handleDeleteInvestment}
                            showDelete={showDelete}
                        />      <ConfirmationDialog />

                    </VStack>
                </form>
            </Box></Box>
    );
};

export { InvestmentView };
