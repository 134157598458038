
// Chakra imports
import { Avatar, Box, Card, Flex, FormLabel, Icon, Select, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdAddTask, MdAdminPanelSettings, MdAttachMoney, MdBarChart, MdCalendarMonth, MdFileCopy, MdFoundation, MdMonetizationOn, MdSupervisedUserCircle, MdVerifiedUser } from 'react-icons/md';
import CheckTable from 'views/admin/rtl/components/CheckTable';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex';
import { useEffect, useState } from 'react';
import { GetMDBCardData } from 'ApiRequestService';
import { getShortMonth } from 'commonfunction/CommonFunction';
import TableTopCreators from '../marketplace/components/TableTopCreators';
import tableDataTopCreators from 'views/admin/marketplace/variables/tableDataTopCreators';

export default function UserReports() {
	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const shortMonth = getShortMonth()

	const [cardData, setCardData] = useState<any>({})

	const [cardData2, setCardData2] = useState<any>({})

	useEffect(() => {
		fetchDashboardCardData()
	}, [])

	const fetchDashboardCardData = async () => {
		try {
			const response = await GetMDBCardData();
			if (response.data) {
				const cardData = response.data.cardData[0];  // Adjust as per actual response structure
				if (cardData) {
					setCardData(cardData)
				}
				const cardData2 = response.data.cardData2[0];  // Adjust as per actual response structure
				if (cardData2) {
					setCardData2(cardData2)
				}
			} else {
				console.log('No data found in response:', response);
			}
		} catch (error) {
			console.log('Error fetching data:', error);
		}
	}


	const formatCurrency = (amount: number) => {
		return new Intl.NumberFormat('en-IN', {
			style: 'currency',
			currency: 'INR',
			maximumFractionDigits: 0,
		}).format(amount);
	};


	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 3, lg: 3, '2xl': 3 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
							icon={<Icon w='32px' h='32px' as={MdAttachMoney} color='white' />}
						/>
					}
					name='Total Funds by Investors'
					value={formatCurrency(cardData.overall_investment_amount)}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdCalendarMonth} color={brandColor} />}
						/>
					}
					name='Funds Raised in last 30 days'

					value={formatCurrency(cardData.last_30_days_investment_amount)} growth={cardData.percentage_change}
				/>
				<MiniStatistics startContent={
					<IconBox
						w='56px'
						h='56px'
						bg={boxBg}
						icon={<Icon w='32px' h='32px' as={MdSupervisedUserCircle} color={brandColor} />}
					/>
				} name='Total Investors' value={cardData.total_active_investors} />
				
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 4, lg: 4, '2xl': 4 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdMonetizationOn} color={brandColor} />}
						/>
					}
					name={`${shortMonth} Month Total Payout`}
					value={formatCurrency(cardData2.total_investor_payment+cardData2.total_portfolio_owner_payment+cardData2.total_sub_marketer_payment)}
				/>
				
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
						/>
					}
					name={`${shortMonth} Month Payout to Investors`}
					value={formatCurrency(cardData2.total_investor_payment)}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
						/>
					}
					name={`${shortMonth}  Month Payout to Portfolios`}
					value={formatCurrency(cardData2.total_portfolio_owner_payment)}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
						/>
					}
					name={`${shortMonth} Month Payout to Sub-Marketers`}
					value={formatCurrency(cardData2.total_sub_marketer_payment)}
				/>
			</SimpleGrid>


			<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
				<TotalSpent />
				<TableTopCreators />
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<WeeklyRevenue />
				<DailyTraffic />
				{/* <CheckTable tableData={tableDataCheck} /> */}
				{/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<DailyTraffic />
					<PieCard />
				</SimpleGrid> */}
			</SimpleGrid>
			{/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<ComplexTable tableData={tableDataComplex} />
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<Tasks />
					<MiniCalendar h='100%' minW='100%' selectRange={false} />
				</SimpleGrid>
			</SimpleGrid> */}
		</Box>
	);
}
