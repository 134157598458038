import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    Grid,
    GridItem,
    VStack,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Icon
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { FormFooter } from 'components/footer/FormFooter';
import { CreateInvestor, UpdateInvestor, DeleteInvestor, GetInvestor } from '../../../ApiRequestService';
import { Investor } from '../FormModels';
import { useNavigate } from 'react-router-dom';
import { dateFormatChange, fetchUserName, reverseDateFormat } from 'commonfunction/CommonFunction';
import useCustomAlert from 'components/alert/alerts';
import { InvestorBankDetails } from './InvestorBankDetails';
import { DecryptObjectData, EncryptObjectData } from 'utils/encryption';
import { MdMoney, MdOutlineMoney } from 'react-icons/md';
import { BsBarChartLineFill } from 'react-icons/bs';

const InvestorView: React.FC = () => {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<Investor>();
    const [formData, setFormData] = useState<any>([]);
    const { showAlert, showDeleteConfirmation, ConfirmationDialog } = useCustomAlert();
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [showBank, setShowBank] = useState<boolean>(false);
    const [currentInvestorId, setCurrentInvestorId] = useState<number | null>(null);

    const currentPath = window.location.pathname;

    const navigate = useNavigate();
    useEffect(() => {
        sessionStorage.setItem('pageName', 'Investor Details')
        if (currentPath.includes('admin/investor-view')) {
            const investorID = sessionStorage.getItem('investor_ID');
            if (investorID) {
                setShowDelete(true)
                setCurrentInvestorId(parseInt(investorID));
                fetchInvestor(investorID);
                setShowBank(true)
            }
        }
    }, [currentPath]);

    // Fetch investor data if ID is present
    const fetchInvestor = async (investorID: string) => {
        try {
            const response = await GetInvestor({ id: investorID });
            if (response && response.data) {
                const investor = DecryptObjectData(response.data.data);  // Adjust as per actual response structure
                if (investor) {
                    investor.dob = reverseDateFormat(investor.dob)
                    investor.doj = reverseDateFormat(investor.doj)
                    investor.created_at = reverseDateFormat(investor.created_at)
                    investor.is_deleted = investor.is_deleted.data[0]
                    setFormData(investor)
                    // Set the form values using the API response
                    reset(investor);  // Reset the form with fetched data
                }
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    // Submit form data (Create or Update)
    const onSubmit = async (datavalue: Investor) => {
        try {
            const data = EncryptObjectData(datavalue)
            data.dob = dateFormatChange(data.dob)
            data.doj = dateFormatChange(data.doj)
            let response;
            if (currentInvestorId !== null) {
                // Update existing investor
                // data.investor_id = currentInvestorId.toString();  // Ensure ID is set
                response = await UpdateInvestor(data);
                if (response.status === 200) {
                    //  navigate('/admin/investor-list')
                    showAlert("success", "Success", "Investor Updated Successfully!");
                } else {
                    showAlert("error", "Error", "Failed to Update Investor");
                }
            } else {
                // Create new investor  
                data.created_by = fetchUserName()
                data.created_at = dateFormatChange(new Date())
                response = await CreateInvestor(data);
                if (response.status === 200) {
                    let investorID = response.data.data.investorId
                    sessionStorage.setItem('investor_ID', investorID);
                    fetchInvestor(investorID);
                    setShowDelete(true)
                    setCurrentInvestorId(parseInt(investorID));
                    setShowBank(true)
                    //  navigate('/admin/investor-list')
                    showAlert("success", "Success", "Investor Saved Successfully!");
                } else {
                    showAlert("error", "Error", "Failed to Save Investor");
                }
            }

        } catch (error) {
            console.log('Error during form submission:', error);
        }
    };

    // Handle delete investor
    const handleDeleteInvestor = async () => {
        if (currentInvestorId !== null) {
            showDeleteConfirmation(async (result) => {
                if (result) {
                    try {
                        const response = await DeleteInvestor({ id: currentInvestorId });

                        if (response.status === 200) {
                            navigate('/admin/investor-list')
                            showAlert("success", "Deleted", "Investor Deleted Successfully!");
                        } else {
                            showAlert("error", "Error", "Failed to Delete Investor");
                        }
                    } catch (error) {
                        console.log('Error deleting investor:', error);
                    }
                } else {
                    showAlert("info", "Cancelled", "Delete operation cancelled.");
                }
            });

        }
    };

    const navigateInvestment = () => {
        navigate('/admin/investor-dashboard');
    }

    const openInNewTab = () => {
        window.open('https://eportal.incometax.gov.in/iec/foservices/#/pre-login/link-aadhaar-status', '_blank', 'noopener,noreferrer');
    };

    return (
        <Box pt={{ base: '90px' }} mb={'60px'}>
            <Box
                border="1px"
                borderColor="gray.400"
                borderRadius="md"
                p={4}
                boxShadow="lg"
            >

                <Box cursor="pointer" hidden={!showBank}>
                    <Button
                        style={{ margin: '2px' }}
                        colorScheme="pink"
                        title="Investment Dashboard"
                        onClick={() => navigateInvestment()}
                    >
                        <BsBarChartLineFill /><span style={{ marginLeft: '3px' }}>Dashboard</span>
                    </Button>
                </Box>
                <Box cursor="pointer" hidden={showBank}>
                    <Button
                        style={{ margin: '2px' }}
                        colorScheme="blue"
                        title="Verify PAN AADHAR Status"
                        onClick={() => openInNewTab()}
                    >
                        <span style={{ marginLeft: '3px' }}>Verify PAN AADHAR Status</span>
                    </Button>
                </Box>

                <Accordion defaultIndex={[0]} allowMultiple>
                    {/* Accordion for Basic Details */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton _expanded={{ bg: 'gray.100', color: 'teal.500' }} bg="gray.500" border={'1px solid black'}>
                                <Box flex="1" textAlign="left" fontWeight="bold">
                                    Investor Basic Details
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <VStack spacing={1}>
                                    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} w='100%'>
                                        {/* Form Fields */}
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>First Name</FormLabel>
                                                <Input {...register('firstname', { required: true })} />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>Relation</FormLabel>
                                                <Select {...register('relation', { required: true })}>
                                                    <option value="S/O">S/O</option>
                                                    <option value="D/O">D/O</option>
                                                    <option value="W/O">W/O</option>
                                                    <option value="C/O">C/O</option>
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>SurName</FormLabel>
                                                <Input {...register('lastname', { required: true })} />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>Gender</FormLabel>
                                                <Select {...register('gender', { required: true })}>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other">Other</option>
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>Date of Birth (DOB)</FormLabel>
                                                <Input type="date" {...register('dob', { required: true })} />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>Date of Joining (DOJ)</FormLabel>
                                                <Input type="date" {...register('doj', { required: true })} />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>Phone</FormLabel>
                                                <Input
                                                    type="text"
                                                    maxLength={10} // Limit input to 10 characters
                                                    {...register('phone', {
                                                        required: true,
                                                        pattern: /^[0-9]{10}$/, // Ensure it's 10 digits
                                                    })}
                                                    placeholder="10-digit phone number"
                                                />
                                                {errors.phone && <p style={{ color: 'red' }}>Phone number must be 10 digits</p>}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>Email</FormLabel>
                                                <Input
                                                    type="email"
                                                    {...register('email', {
                                                        required: true,
                                                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Basic email format
                                                    })}
                                                />
                                                {errors.email && <p style={{ color: 'red' }}>Invalid email address</p>}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>PAN Number</FormLabel>
                                                <Input
                                                    maxLength={10} // Limit input to 11 characters
                                                    {...register('pan_number', {
                                                        required: true,
                                                        pattern: /^[A-Z0-9]{10}$/, // Ensure it's 11 alphanumeric characters
                                                    })}
                                                />
                                                {errors.pan_number && <p style={{ color: 'red' }}>PAN must be 10 alphanumeric characters</p>}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>Aadhar Number</FormLabel>
                                                <Input
                                                    maxLength={14} // Limit input to 14 characters (including dashes)
                                                    {...register('aadar_number', {
                                                        required: true,
                                                        pattern: /^[0-9]{4}-[0-9]{4}-[0-9]{4}$/, // Enforces the 1111-2222-3333 format
                                                    })}
                                                    placeholder="XXXX-XXXX-XXXX"
                                                    onChange={(e) => {
                                                        let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
                                                        if (value.length > 4) value = value.replace(/(\d{4})(\d+)/, '$1-$2');
                                                        if (value.length > 9) value = value.replace(/(\d{4})-(\d{4})(\d+)/, '$1-$2-$3');
                                                        e.target.value = value;
                                                    }}
                                                />
                                                {errors.aadar_number && <p style={{ color: 'red' }}>Aadhar must be in the format 1111-2222-3333</p>}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>Pincode</FormLabel>
                                                <Input
                                                    maxLength={6} // Limit input to 6 characters
                                                    {...register('pincode', {
                                                        required: true,
                                                        pattern: /^[0-9]{6}$/, // Ensure it's 6 digits
                                                    })}
                                                />
                                                {errors.pincode && <p style={{ color: 'red' }}>Pincode must be 6 digits</p>}
                                            </FormControl>
                                        </GridItem>
                                        <GridItem>
                                            <FormControl isRequired>
                                                <FormLabel>State</FormLabel>
                                                <Input {...register('state', { required: true })} />
                                            </FormControl>
                                        </GridItem>
                                        <GridItem >
                                            <FormControl isRequired>
                                                <FormLabel>Address</FormLabel>
                                                <Textarea {...register('address', { required: true })} />
                                            </FormControl>
                                        </GridItem>
                                    </Grid>

                                    {/* Footer with Submit and Delete Buttons */}
                                    <FormFooter
                                        createdBy={formData.created_by}
                                        modifiedBy={''}
                                        createddate={formData.created_at}
                                        modifieddate={''}
                                        buttonName={'Submit'}
                                        navigatebackURL={'/admin/investor-list'}
                                        disabled={false}
                                        onDelete={handleDeleteInvestor}
                                        showDelete={showDelete}
                                    />
                                </VStack>
                                <ConfirmationDialog />
                            </form>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem hidden={!showBank}>
                        <h2>
                            <AccordionButton _expanded={{ bg: 'gray.100', color: 'teal.500' }} bg="gray.500" border={'1px solid black'}>
                                <Box flex="1" textAlign="left" fontWeight="bold">
                                    Investor Bank Details
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <InvestorBankDetails />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
        </Box>
    );
};

export { InvestorView };
