import React, { useEffect, useState } from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, FormLabel,
    Input, Table, Tbody, Tr, Td, Th, Thead, IconButton, Box, FormErrorMessage
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { CreateInvestorBankDetails, DeleteInvestorBankDetails, ListInvestorBankDetails, UpdateInvestorBankDetails } from 'ApiRequestService';
import useCustomAlert from 'components/alert/alerts';
import { dateFormatChange, fetchUserName } from 'commonfunction/CommonFunction';
import { BsBank2 } from 'react-icons/bs';
import { DecryptDataList, EncryptObjectData, encryptSeprateData } from 'utils/encryption';


interface InvestorBankListModal {
    investor_account_id?: number;
    investor_id: number;
    bank_name: string;
    account_number: string;
    ifsc_code: string;
    branch_name: string;
    account_holder: string;
    notes: string;
    created_at?: string;
    created_by: string;
    is_deleted?: boolean;
}

const InvestorBankDetails: React.FC = () => {

    const [investorBankList, setInvestorBankList] = useState<InvestorBankListModal[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentBank, setCurrentBank] = useState<InvestorBankListModal | null>(null);
    const [investorId, setInvestorId] = useState<number | null>(null);
    const [errors, setErrors] = useState<any>({});
    const { showAlert, showDeleteConfirmation, ConfirmationDialog } = useCustomAlert();

    const investorID = sessionStorage.getItem('investor_ID');
    useEffect(() => {

        const currentPath = window.location.pathname;
        if (currentPath.includes('admin/investor-view')) {
            setInvestorId(parseInt(investorID || '0'));
            fetchAllInvestorBanks();
            const isAddBank = sessionStorage.getItem('isAddBank');
            if (isAddBank === 'true') {
                openModal()
            }
        }
    }, []);

    // Fetch investors Bank Details
    const fetchAllInvestorBanks = async () => {
        try {
            const response = await ListInvestorBankDetails({ id: investorID });
            if (response && response.data) {
                const decrtptedData = DecryptDataList(response.data.data) as InvestorBankListModal[]
                setInvestorBankList(decrtptedData);
            } else {
                console.log('No data found in response:', response);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const openModal = (bank: InvestorBankListModal | null = null) => {
        setCurrentBank(bank || {
            investor_id: investorId!,
            bank_name: '',
            account_number: '',
            ifsc_code: '',
            branch_name: '',
            account_holder: '',
            notes: '',
            created_by: fetchUserName(),
        });
        setErrors({});
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentBank(null);
    };

    const handleSave = async () => {
        const isValid = validateForm();
        if (!isValid) return;

        try {
            let response;
            const encrptedcurrentBank = EncryptObjectData(currentBank)
            if (encrptedcurrentBank) {
                encrptedcurrentBank.is_deleted = false;
                encrptedcurrentBank.investor_id = parseInt(investorID || '0');

                if (encrptedcurrentBank.investor_account_id) {
                    // Edit logic
                    encrptedcurrentBank.created_at = dateFormatChange(encrptedcurrentBank.created_at);
                    response = await UpdateInvestorBankDetails(encrptedcurrentBank);
                } else {
                    // Add logic
                    encrptedcurrentBank.created_at = dateFormatChange(new Date());
                    response = await CreateInvestorBankDetails(encrptedcurrentBank);
                }

                if (response?.status === 200) {
                    showAlert("success", "Success", encrptedcurrentBank.investor_account_id ? "Investor Bank Details Updated Successfully!" : "Investor Bank Details Saved Successfully!");
                    fetchAllInvestorBanks();
                } else {
                    showAlert("error", "Error", "Failed to Save Investor Bank Details");
                }
            }
        } catch (error) {
            console.log('Error during form submission:', error);
        }
        closeModal();
    };

    const handleDelete = async (id: number) => {
        showDeleteConfirmation(async (result) => {
            if (result) {
                try {
                    const response = await DeleteInvestorBankDetails({ id });
                    if (response.status === 200) {
                        showAlert("success", "Deleted", "Investor Bank Deleted Successfully!");
                        fetchAllInvestorBanks();
                    } else {
                        showAlert("error", "Error", "Failed to Delete Investor Bank");
                    }
                } catch (error) {
                    console.log('Error deleting investor bank:', error);
                }
            } else {
                showAlert("info", "Cancelled", "Delete operation cancelled.");
            }
        });

    };

    // Handle IFSC code input and fetch bank details
    const handleIfscCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const ifscCode = e.target.value.toUpperCase();
        if (ifscCode.length <= 11 && /^[A-Z0-9]*$/.test(ifscCode)) {
            setCurrentBank((prev) => prev ? { ...prev, ifsc_code: ifscCode } : null);
        }

        if (ifscCode.length === 11) {
            try {
                const response = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
                if (response.status === 200) {
                    const data = await response.json();
                    if (data) {
                        setCurrentBank((prev) => prev ? { ...prev, bank_name: (data.BANK).toUpperCase(), branch_name: data.BRANCH } : null);
                    }
                }
            } catch (error) {
                console.log('Error fetching bank details:', error);
                showAlert("error", "Error", "Invalid IFSC Code or Failed to Fetch Bank Details");
            }
        }
    };

    const handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const accountNumber = e.target.value;
        if (/^\d*$/.test(accountNumber)) {
            setCurrentBank((prev) => prev ? { ...prev, account_number: accountNumber } : null);
        }
    };


    const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const accountName = e.target.value;
        if (/^[A-Za-z\s]*$/.test(accountName)) {
            setCurrentBank((prev) => prev ? { ...prev, account_holder: accountName } : null);
        }
    };

    const validateForm = () => {
        let newErrors: any = {};
        if (!currentBank?.ifsc_code || currentBank.ifsc_code.length !== 11) {
            newErrors.ifsc_code = "IFSC code must be 11 characters long.";
        }
        if (!currentBank?.bank_name) {
            newErrors.bank_name = "Bank name is required.";
        }
        if (!currentBank?.account_number) {
            newErrors.account_number = "Account number is required.";
        }
        if (!currentBank?.branch_name) {
            newErrors.branch_name = "Branch name is required.";
        }
        if (!currentBank?.account_holder) {
            newErrors.account_holder = "Account holder name is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <div>
            <Button colorScheme="teal" ms={4} onClick={() => openModal()}><BsBank2 /><span style={{ marginLeft: '2px' }}>Add Bank</span></Button>

            <Table mt={4}>
                <Thead>
                    <Tr>
                        <Th>Bank Name</Th>
                        <Th>Account Number</Th>
                        <Th>IFSC Code</Th>
                        <Th>Branch Name</Th>
                        <Th>Account Holder</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {investorBankList.length > 0 ?
                        investorBankList.map((bank) => (
                            <Tr key={bank.investor_account_id}>
                                <Td>{bank.bank_name}</Td>
                                <Td>{bank.account_number}</Td>
                                <Td>{bank.ifsc_code}</Td>
                                <Td>{bank.branch_name}</Td>
                                <Td>{bank.account_holder}</Td>
                                <Td>
                                    <IconButton aria-label="Edit" icon={<EditIcon />} colorScheme="blue" onClick={() => openModal(bank)} />
                                    <IconButton aria-label="Delete" icon={<DeleteIcon />} colorScheme="red" onClick={() => handleDelete(bank.investor_account_id!)} ml={2} />
                                </Td>
                            </Tr>
                        )) : <Box textAlign={'center'} fontWeight={800}>No Bank Found!!</Box>}
                </Tbody>
            </Table>

            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{currentBank?.investor_account_id ? 'Edit Bank' : 'Add Bank'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={2} isInvalid={errors.account_number} isRequired>
                            <FormLabel>Account Number</FormLabel>
                            <Input value={currentBank?.account_number || ''} onChange={handleAccountNumberChange} />
                            {errors.account_number && <FormErrorMessage>{errors.account_number}</FormErrorMessage>}
                        </FormControl>

                        <FormControl mb={2} isInvalid={errors.ifsc_code} isRequired>
                            <FormLabel>IFSC Code</FormLabel>
                            <Input value={currentBank?.ifsc_code || ''} onChange={handleIfscCodeChange} />
                            {errors.ifsc_code && <FormErrorMessage>{errors.ifsc_code}</FormErrorMessage>}
                        </FormControl>

                        <FormControl mb={2} isInvalid={errors.bank_name} isRequired>
                            <FormLabel>Bank Name</FormLabel>
                            <Input value={currentBank?.bank_name || ''} onChange={(e) => setCurrentBank((prev) => prev ? { ...prev, bank_name: (e.target.value).toUpperCase() } : null)} />
                            {errors.bank_name && <FormErrorMessage>{errors.bank_name}</FormErrorMessage>}
                        </FormControl>


                        <FormControl mb={2} isInvalid={errors.branch_name} isRequired>
                            <FormLabel>Branch Name</FormLabel>
                            <Input value={currentBank?.branch_name || ''} onChange={(e) => setCurrentBank((prev) => prev ? { ...prev, branch_name: e.target.value } : null)} />
                            {errors.branch_name && <FormErrorMessage>{errors.branch_name}</FormErrorMessage>}
                        </FormControl>

                        <FormControl mb={2} isInvalid={errors.account_holder} isRequired>
                            <FormLabel>Account Holder</FormLabel>
                            <Input value={currentBank?.account_holder || ''} onChange={handleAccountNameChange} />
                            {errors.account_holder && <FormErrorMessage>{errors.account_holder}</FormErrorMessage>}
                        </FormControl>

                        <FormControl mb={2}>
                            <FormLabel>Notes</FormLabel>
                            <Input value={currentBank?.notes || ''} onChange={(e) => setCurrentBank((prev) => prev ? { ...prev, notes: e.target.value } : null)} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" mr={3} onClick={handleSave}>{currentBank?.investor_account_id ? 'Update' : 'Save'}</Button>
                        <Button onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <ConfirmationDialog />
        </div>
    );
};

export { InvestorBankDetails };
