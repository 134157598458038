import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import routes, { getRoutes } from '../../routes';
import Privateroutes from '../../privateRoutes';
import { getIsAdmin } from 'commonfunction/CommonFunction';

export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const location = useLocation();
  const [routesArray, setRoutesArray] = useState([]);
  const [isAdmin, setIsAdmin] = useState(getIsAdmin());
  const [pageName, setPageName] = useState('IPM'); // Default page name

  useEffect(() => {
    const newRoutes = getRoutes();
    setRoutesArray(newRoutes);
  }, [isAdmin]);

  useEffect(() => {
    const currentPageName = sessionStorage.getItem('pageName') || 'IPM';
    setPageName(currentPageName); // Update page name whenever route changes
  }, [location.pathname]); // Depend on location to update page name

  const getActiveRoute = (routesList: RoutesType[]): string => {
    const activeRoute = routesList.find(
      route => location.pathname.indexOf(route.layout + route.path) !== -1
    );

    return activeRoute ? activeRoute.name : pageName;
  };

  const getActiveNavbar = (routesList: RoutesType[]): boolean => {
    const activeRoute = routesList.find(
      route => location.pathname.indexOf(route.layout + route.path) !== -1
    );
    return activeRoute ? activeRoute.secondary : false;
  };

  const renderRoutes = (routesList: RoutesType[]) => {
    return routesList.map((route, key) => {
      if (route.layout === '/admin') {
        return <Route path={`${route.path}`} element={route.component} key={key} />;
      }
      return null;
    });
  };

  const { onOpen } = useDisclosure();

  return (
    <Box>
      <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        <Sidebar routes={routesArray} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc(100% - 290px)' }}
          maxWidth={{ base: '100%', xl: 'calc(100% - 290px)' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                fixed={false}
                onOpen={onOpen}
                logoText="IPM Dashboard"
                brandText={getActiveRoute(routesArray)}
                secondary={getActiveNavbar(routesArray)}
                message={getActiveRoute(routesArray)}
                {...rest}
              />
            </Box>
          </Portal>

          {location.pathname !== '/admin/full-screen-maps' && (
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Routes>
                {renderRoutes(routesArray)}
                {renderRoutes(Privateroutes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/investor-list" replace />}
                />
              </Routes>
            </Box>
          )}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
