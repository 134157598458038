/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import { Box, Button, ButtonGroup, Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

interface Footerprops {
    createdBy: string;
    modifiedBy: string;
    createddate: string;
    modifieddate: string;
    buttonName: string;
    navigatebackURL: string;
    disabled: boolean;
    isFormDataChanged?: boolean;
    onDelete?: () => void;
    showDelete?: boolean;
}

const FormFooter: React.FC<Footerprops> = ({ createdBy, createddate, modifiedBy, modifieddate, buttonName,showDelete, navigatebackURL,onDelete, disabled, isFormDataChanged }) => {

    const [isDisable, setIsDisable] = useState<boolean>(false)

    const navigate = useNavigate();

    const navigateBack = () => {
        // if (isFormDataChanged) {
        //   CloseConfirmAlert(async (isConfirmed) => {
        //     if (isConfirmed) {
        //       navigate(navigatebackURL)
        //     }
        //   })
        // } else {
        navigate(navigatebackURL)
        // }

    }


    return (
        <>
            <Flex
                className='footer-font'
                 width="80%"
                justifyContent="space-between"
                alignItems="center"
                bg="gray.700"         // Background color
                color="white"         // Text color
                position="fixed"      // Make it fixed at the bottom
                bottom="0"            // Position it at the bottom
                p={2}    
                ms={1}             // Add padding
                zIndex="1000"         // Ensure it stays on top
            >
                {/* Created By and Created Date */}
                <Box fontFamily="serif" fontWeight="800">
                    <Text>Created By: {createdBy}</Text>
                </Box>

                {/* Buttons */}
                <Box textAlign="center">
                    <Button
                        type="submit"
                        hidden={isDisable || disabled}
                        disabled={isDisable || disabled || !isFormDataChanged}
                        colorScheme="green"
                        variant="solid"
                        mr={3}
                    >
                        {buttonName}
                    </Button>
                    <Button  mr={3} type="button" onClick={navigateBack} colorScheme="blue">
                        Close
                    </Button> 
                    <Button hidden={!showDelete} type="button" onClick={onDelete} colorScheme="red">
                        Delete
                    </Button>
                </Box>

                {/* Modified By and Modified Date */}
                <Box fontFamily="serif" fontWeight="800" textAlign="right">
                <Text>Created Date: {createddate}</Text>
                    {/* <Text>Modified By: {modifiedBy}</Text> */}
                    {/* <Text>Modified Date: {modifieddate}</Text> */}
                </Box>
            </Flex>
        </>
    )
}

export { FormFooter }