import CryptoJS from 'crypto-js';

const secretKey = `\xac\x1b\x93\x03+d\xa5:\xeb\x8b%q\x9a6r\\g\n\xcd\x9d\xe0\x86l\xc7'{\x94\xdf1+\r\x1d`; // Replace with your actual secret key

const sensitiveKeys = ['bank_name', 'account_number', 'ifsc_code', 'branch_name', 'account_holder','account_holder', 'phone', 'email', 
  'pan_number', 'aadar_number', 'address', 'pincode',];

export const encryptData = (data: any): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (encryptedData: string): any => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};



export function encryptSeprateData(data: string | CryptoJS.lib.WordArray) {
  try {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  } catch (e) {
    console.error("Encryption error:", e);
    throw e;
  }
};

export function decryptSeprateData(ciphertext: string | CryptoJS.lib.CipherParams) {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return (bytes.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    console.error("Decryption error:", e);
    throw e;
  }
};



// Function to encrypt values of an object
export const EncryptObjectData = <T extends object>(data: T): T => {
  const encryptedObject: Partial<T> = { ...data }; // Create a clone of the original object

  // Loop over each key-value pair in the object
  Object.keys(data).forEach(key => {
    const value = data[key as keyof T];

    // Encrypt only string values
    if (value && typeof value === 'string' && value != "" && sensitiveKeys.includes(key)) {
      encryptedObject[key as keyof T] = encryptSeprateData(value) as any;
    }
  });

  return encryptedObject as T;
};

// Function to encrypt a list of objects
export const EncryptDataList = <T extends object>(dataList: T[]): T[] => {
  return dataList.map(item => EncryptObjectData(item));
};


// Function to decrypt values of an object
export const DecryptObjectData = <T extends object>(data: T): T => {
  const decryptedObject: Partial<T> = { ...data }; // Create a clone of the original object

  // Loop over each key-value pair in the object
  Object.keys(data).forEach(key => {
    const value = data[key as keyof T];

    // Decrypt only string values
    if (value && typeof value === 'string' && value != "" && sensitiveKeys.includes(key)) {
      decryptedObject[key as keyof T] = decryptSeprateData(value) as any;
    }
  });

  return decryptedObject as T;
};

// Function to encrypt a list of objects
export const DecryptDataList = <T extends object>(dataList: T[]): T[] => {
  return dataList.map(item => DecryptObjectData(item));
};


