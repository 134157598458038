import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useEffect, useState } from 'react';
import Privateroutes  from './privateRoutes';

import SignInCentered from 'views/auth/signIn';
// Chakra imports

export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  var AppUser = JSON.parse(sessionStorage.getItem('appUser')) || []
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(AppUser.appuser_id) || false);
  
  useEffect(() => {
    if (AppUser.appuser_id) {
      setIsLoggedIn(true)
    }
  }, [])

  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        {/* <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} /> */}

        {/* {isLoggedIn ? (
          <>
            <Route path="admin/*"
              element={
                <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
              } />
          </>
        ) : (
          <> */}
            <Route path='/' element={<Navigate to='/auth/sign-in' />} />
            <Route path="auth/*" element={<SignInCentered />} />
            <Route path="admin/*"
              element={
                <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
              } />
          {/* </>
        )} */}
      </Routes>
    </ChakraProvider>
  );
}
