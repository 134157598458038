import axios from 'axios';
import { encryptData, decryptData } from '../utils/encryption';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // if (config.data) {
    //   config.data = { data: encryptData(config.data) };
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
   
    return response;
  },
  (error) => {
    if (error.response?.status === 403) {
      console.log("Session Expired");
     // showAlert("warning", "Warning", "User Session Expired!! Logging Out....");
      localStorage.clear();
      sessionStorage.clear()
      window.location.replace('http://localhost:3000/auth/sign-in');
      // Handle session expiration or unauthorized access
    }
    if (error.response?.status === 401) {
      console.log("Unauthorized! Redirecting to login.");
      // Handle session expiration or unauthorized access
    }
    return Promise.reject(error);
  }
);

export default api;
