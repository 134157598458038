import { useToast, Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/react";
import { useState, useRef } from "react";

const useCustomAlert = () => {
  const toast = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState<"delete" | "save" | null>(null);
  const [onConfirm, setOnConfirm] = useState<null | ((result: boolean) => void)>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const showAlert = (status: "success" | "error" | "info" | "warning", title: string, description: string) => {
    toast({
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const showDeleteConfirmation = (callback: (result: boolean) => void) => {
    setConfirmationType("delete");
    setIsDialogOpen(true);
    setOnConfirm(() => callback);
  };

  const showSaveConfirmation = (callback: (result: boolean) => void) => {
    setConfirmationType("save");
    setIsDialogOpen(true);
    setOnConfirm(() => callback);
  };

  const handleConfirm = (result: boolean) => {
    setIsDialogOpen(false);
    if (onConfirm) {
      onConfirm(result);
    }
  };

  const ConfirmationDialog = () => (
    <AlertDialog
      isOpen={isDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => handleConfirm(false)}
    >
      <AlertDialogOverlay
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <AlertDialogContent maxWidth="sm" >
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {confirmationType === "delete" ? "Confirm Deletion" : "Confirm Save"}
          </AlertDialogHeader>

          <AlertDialogBody>
            {confirmationType === "delete" ? (
              <>
                <h3>Please Confirm!</h3>
                Are you sure you want to delete this item?
              </>
            ) : (
              <>
                <h3>Please Confirm!</h3>
                This action will send an email to the respective investor. Do you want to proceed?
              </>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={() => handleConfirm(false)}>
              Cancel
            </Button>
            <Button colorScheme={confirmationType === "delete" ? "red" : "blue"} onClick={() => handleConfirm(true)} ml={3}>
              {confirmationType === "delete" ? "Delete" : "Save"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );

  return { showAlert, showDeleteConfirmation, showSaveConfirmation, ConfirmationDialog };
};

export default useCustomAlert;
