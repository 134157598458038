// Chakra imports
import { Flex, useColorModeValue,Image,Text } from '@chakra-ui/react';
import petimage from 'assets/img/layout/PETLogo.jpeg'

// Custom components
import { AppleLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Image   h='45px' w='160px'
				src={petimage}
				alt="Logo"
			/><Text
			fontSize="md"
			fontWeight="900"
			color="teal"
			textAlign="center"
			letterSpacing="wider"
			mb="8px"
		>
			Investor Management System
		</Text>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
