import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdShowChart,
  MdPieChart,
  MdPhotoCameraFront,
  MdReportOff,
  MdSupervisedUserCircle,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import {InvestorView} from 'views/forms/investor/InvestorView';
import { InvestorList } from 'views/forms/investor/InvestorList';
import { ReportView } from 'views/forms/reports/ReportsView';
import { getIsAdmin } from 'commonfunction/CommonFunction';
import { PortfolioList } from 'views/forms/portfolio/PortfolioList';

const allRoutes = [
  {
    name: 'Admin Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdPieChart} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Investors',
    layout: '/admin',
    path: '/investor-list',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <InvestorList />,
  },  
  {
    name: 'Portfolios',
    layout: '/admin',
    path: '/portfolio-list',
    icon: <Icon as={MdSupervisedUserCircle} width="20px" height="20px" color="inherit" />,
    component: <PortfolioList />,
  },  
  {
    name: 'Reports',
    layout: '/admin',
    path: '/report-view',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: <ReportView />,
  },
  // {
  //   name: 'NFT Marketplace',
  //   layout: '/admin',
  //   path: '/nft-marketplace',
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: 'Data Tables',
  //   layout: '/admin',
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: '/data-tables',
  //   component: <DataTables />,
  // },
  // {
  //   name: 'Profile',
  //   layout: '/admin',
  //   path: '/profile',
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: <Profile />,
  // },
  // {
  //   name: 'RTL Admin',
  //   layout: '/rtl',
  //   path: '/rtl-default',
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: <RTL />,
  // },
];

const routes = getIsAdmin() ?  allRoutes : allRoutes.filter(route => route.name === 'Investors');

export default routes;

export function getRoutes(){
  const routes = getIsAdmin() ?  allRoutes : allRoutes.filter(route => route.name === 'Investors');
  return routes
}
