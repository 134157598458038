// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
// Custom components
import React, { useEffect, useState } from 'react';
import { MdBarChart } from 'react-icons/md';
import { GetMDBMonthChartData } from 'ApiRequestService';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

export default function MonthlyPayoutChart(props: { [x: string]: any }) {
	const { ...rest } = props;

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const iconColor = useColorModeValue('brand.500', 'white');
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

	const [chartData, setChartData] = useState({
		series: [],
		options: {
			chart: {
				type: 'bar',
				height: 350, // Setting dynamic height based on data (initially fixed)
				stacked: true,
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					barHeight: '20px', // Fixed bar height
					borderRadius: 10,
					dataLabels: {
						total: {
							enabled: true,
							offsetX: 0,
							style: {
								fontSize: '13px',
								fontWeight: 900,
							},
						},
					},
				},
			},
			stroke: {
				width: 1,
				colors: ['#fff'],
			},
			xaxis: {
				categories: [],
				labels: {
					style: {
						colors: '#A3AED0',
						fontSize: '14px',
						fontWeight: '500',
					},
					formatter: function (val) {
						return val + 'K';
					},
				},
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			yaxis: {
				title: {
					text: undefined,
				},
				labels: {
					style: {
						colors: '#A3AED0',
						fontSize: '14px',
						fontWeight: '500',
					},
				},
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return val + 'K';
					},
				},
				style: {
					fontSize: '12px',
				},
				theme: 'dark',
			},
			fill: {
				opacity: 1,
				colors: ['#5E37FF', '#6AD2FF', '#E1E9F8'],
			},
			legend: {
				show: true,
				position: 'top',
				horizontalAlign: 'left',
				offsetX: 40,
				fontSize: '14px',
				labels: {
					colors: '#A3AED0',
				},
				markers: {
					radius: 10,
					fillColors: ['#5E37FF', '#6AD2FF', '#E1E9F8'], // Matching colors with the bar
				},
				itemMargin: {
					horizontal: 10,
					vertical: 5,
				},
			},
			grid: {
				borderColor: 'rgba(163, 174, 208, 0.3)',
				show: true,
				yaxis: {
					lines: {
						show: true,
					},
				},
				xaxis: {
					lines: {
						show: false,
					},
				},
			},
		} as ApexOptions,
	});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchMonthlyData();
	}, []);

	const fetchMonthlyData = async () => {
		try {
			const response = await GetMDBMonthChartData();
			if (response && response.data) {
				const dashboardData = response.data.data;
				if (dashboardData.length > 0) {
					const tempData1 = dashboardData.map((item: any) => Number(item.total_investor_payout));
					const tempData2 = dashboardData.map((item: any) => Number(item.total_portfolio_owner_payout));
					const tempData3 = dashboardData.map((item: any) => Number(item.total_sub_marketer_payout));
					const tempDataMonth = dashboardData.map((item: any) => item.month);

					setChartData({
						series: [
							{
								name: 'Investor Payout',
								data: tempData1,
							},
							{
								name: 'Portfolio Owner Payout',
								data: tempData2,
							},
							{
								name: 'Sub-Marketer Payout',
								data: tempData3,
							},
						],
						options: {
							...chartData.options,
							chart: {
								height: tempDataMonth.length * 50, // Dynamic height based on the number of bars
							},
							xaxis: {
								categories: tempDataMonth,
							},
						},
					});
				}
			} else {
				console.log('No data found in response:', response);
			}
		} catch (error) {
			console.log('Error fetching data:', error);
		}
	};

	return (
		<Card alignItems="center" flexDirection="column" w="100%" {...rest}>
			<Flex align="center" w="100%" px="15px" py="10px">
				<Text me="auto" color={textColor} fontSize="xl" fontWeight="700" lineHeight="100%">
					Monthly Payout Chart
				</Text>
				<Button
					alignItems="center"
					justifyContent="center"
					bg={bgButton}
					_hover={bgHover}
					_focus={bgFocus}
					_active={bgFocus}
					w="37px"
					h="37px"
					lineHeight="100%"
					borderRadius="10px"
					{...rest}
				>
					<Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
				</Button>
			</Flex>

			<Box h="270px" mt="auto" w="100%" overflowY="scroll">
				{/* Set overflowY for scrollability */}
				<ReactApexChart options={chartData.options} series={chartData.series} type="bar" />
			</Box>
		</Card>
	);
}
