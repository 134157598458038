import React, { FC, useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { InvestmentAggrement } from 'ApiRequestService';
import { DecryptObjectData } from 'utils/encryption';


interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    InvestmentId: any;
}


const LoanAgreementModal: React.FC<ModalProps> = ({ isOpen, onClose, InvestmentId }) => {
    const [pdfData, setPdfData] = useState(null);

    // Fetch loan data dynamically from an API
    useEffect(() => {
        const fetchLoanData = async () => {
            try {
                const response = await InvestmentAggrement({ id: InvestmentId });
                if (response && response.data) {
                    let decrypteddata = (response.data.base64Data)  // Adjust as per actual response structure
                    if (decrypteddata) {
                        // Map through the decrypted bank list and format the name
                        setPdfData(decrypteddata)
                    }
                } else {
                    console.log('No data found in response:', response);
                }
            } catch (error) {
                console.error('Error fetching loan data:', error);
            }
        };

        if (isOpen) {
            fetchLoanData();
        }
    }, [isOpen]);

  

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xxl">
            <ModalOverlay />
            <ModalContent id="modal-content">
                <ModalHeader>Loan Agreement</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {pdfData ? (
                        <iframe
                            src={`data:application/pdf;base64,${pdfData}`}
                            width="100%"
                            height="500px"
                            title="Investment Agreement"
                        />
                    ) : (
                        <Text>Loading PDF...</Text>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export { LoanAgreementModal };
