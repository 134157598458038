import { Icon } from '@chakra-ui/react';
import { InvestorList } from './views/forms/investor/InvestorList'; // Replace with your actual normal page component
import { MdPerson } from 'react-icons/md';
import { InvestorView } from 'views/forms/investor/InvestorView';
import { InvestorDashboard } from 'views/forms/investor/InvestorDashboard';
import SignInCentered from 'views/auth/signIn';
import { InvestmentView } from 'views/forms/investment/InvestmentView';
import { PortfolioDashboard } from 'views/forms/portfolio/PortfolioDashboard';

const Privateroutes = [
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  },
  {
    name: 'Investor Create',
    layout: '/admin',
    path: '/investor-add',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <InvestorView />,
  }, {
    name: 'Investor view',
    layout: '/admin',
    path: '/investor-view',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <InvestorView />,
  },
  {
    name: 'Investor List',
    layout: '/admin',
    path: '/investor-list',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <InvestorList />,
  },
  {
    name: 'Investor Dashboard',
    layout: '/admin',
    path: '/investor-dashboard',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <InvestorDashboard />,
  }, {
    name: 'Investment Create',
    layout: '/admin',
    path: '/investment-add',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <InvestmentView />,
  }, {
    name: 'Investment view',
    layout: '/admin',
    path: '/investment-view',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <InvestmentView />,
  }, {
    name: 'Portfolio Dashboard',
    layout: '/admin',
    path: '/portfolio-dashboard',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <PortfolioDashboard />,
  },
  // Add more routes as needed
];

export default Privateroutes